/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable react/no-unknown-property */
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import { Card, Form } from "react-bootstrap";
import { Loader, Pagination } from "../components/globals";
import { AccountContext } from "../components/providers/Account";
import { FaCheck, FaFolder, FaSearch } from "react-icons/fa";
import { useShowroomServiceFetch } from "hooks";
import productFilter from "helpers/productFilter";
import { useNavigate } from "react-router-dom";
import ProductSelector from "components/globals/ProductSelector";

const ModelDetailsPage = () => {
  const [organizationId, setOrganizationId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [optionsPerPage] = useState(8);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const filterInputRef = useRef(null);
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState(null);

  const { getSession } = useContext(AccountContext);

  const { data, loading } = useShowroomServiceFetch(
    `organizations/${organizationId}/models`
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (!loading) {
      setCurrentOptions(data);
      setFilteredProducts(data);
    }
  }, [loading]);

  useLayoutEffect(() => {
    getSession().then((data) => {
      setOrganizationId(data.idToken.payload["custom:org_id"]);
    });
  }, []);

  useEffect(() => {
    if (filteredProducts?.length > 0) {
      const indexOfLastOption = currentPage * optionsPerPage;
      const indexOfFirstOption = indexOfLastOption - optionsPerPage;
      setCurrentOptions(
        filteredProducts.slice(indexOfFirstOption, indexOfLastOption)
      );
    } else {
      setCurrentOptions(data);
    }
  }, [data, loading, filteredProducts, currentPage]);

  useEffect(() => {
    const filteredDataByName = productFilter({
      products: data,
      nameInput: filterInput,
    });
    setFilteredProducts(filteredDataByName);
  }, [filterInput]);

  // useEffect(() => {
  //   if (selectedProduct?.product_id) {
  //     setCurrentOptions(data.modelsByProduct[selectedProduct.product_id]);
  //   }
  // }, [selectedProduct, data]);

  return (
    <>
      <div className="main-heading">
        <div>
          <h3 className="mt-2">Models</h3>
        </div>
        <div
          className="btn btn-outline-light lato-regular mt-2"
          onClick={() => navigate("/dashboard/upload-products?mode=models")}
        >
          Manage Models
        </div>
      </div>
      <div className="main-heading mt-1 d-flex align-items-center justify-content-start">
        <div className="w-50% search-bar">
          <FaSearch
            style={{ marginLeft: "1rem", position: "absolute" }}
            color="white"
          />
          <Form.Control
            className="search-input"
            ref={filterInputRef}
            onChange={(event) => {
              setFilterInput(event.target.value);
            }}
            placeholder={"Search"}
          />
        </div>
        <div id="product-select">
          <ProductSelector
            products={data.productModels}
            setSelectedProduct={setSelectedProduct}
          />
        </div>
      </div>
      <div id="projects-preview" className="koulen-regular mt-3">
        <div id="view-project-cards">
          {filteredProducts?.length > 0 ? (
            <>
              {currentOptions?.map((model) => {
                return (
                  <div className="product-card-container mt-1" key={model.id}>
                    {model.path?.length > 0 && (
                      <div className="product-card-image d-flex justify-content-end">
                        <iframe
                          src={`https://www.iframe.mantisxr.com/?model=${model.path}`}
                          style={{ height: "200px", width: "auto" }}
                        />
                      </div>
                    )}
                    <Card.Body>
                      <Card.Title className="lato-regular card-title">
                        {model.name.length > 20
                          ? `${model.name.substr(0, 19)}...`
                          : model.name}
                      </Card.Title>
                      {model.showrooms.length > 0 ? (
                        <b className="live-text lato-regular">
                          <FaCheck
                            color="#3BFA87"
                            className="me-1"
                            size="10px "
                          />
                          {"Live"}
                        </b>
                      ) : (
                        <b className="live-text lato-regular">
                          <FaCheck
                            color="#3BFA87"
                            className="me-1"
                            size="10px "
                          />
                          {"Unassigned"}
                        </b>
                      )}
                      <div className="d-flex">
                        <FaFolder
                          color="white"
                          size="15px"
                          className="me-1 mt-1"
                        />
                        <p className="lato-regular">
                          {model?.showrooms?.length || 0}
                        </p>
                      </div>
                    </Card.Body>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {loading ? (
                <Loader />
              ) : (
                <div id="no-projects">
                  <h2>You currently do not have any models</h2>
                  <div
                    className="btn btn-outline-light lato-regular mt-3"
                    onClick={() =>
                      navigate("/dashboard/upload-products?mode=models")
                    }
                  >
                    {" "}
                    + Add Model
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {filteredProducts?.length > 1 && (
        <div className="main-heading main-pagination-container">
          <div className="pagination-container">
            <Pagination
              optionsPerPage={optionsPerPage}
              totalOptions={filteredProducts?.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ModelDetailsPage;
