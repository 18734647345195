import { useContext, useEffect, useRef, useState } from "react";
import { Pagination, ShowAlert, SpinnerLoader } from "components/globals";
import { FaSearch } from "react-icons/fa";
import { Form, Spinner } from "react-bootstrap";
import LogoGrid from "components/LogosPage/LogoGrid";
import logoFilter from "helpers/logosFilter";
import AssetService from "services/AssetService";
import { AccountContext } from "components/providers/Account";
import { generateUUID } from "three/src/math/MathUtils";
import { useShowroomServiceFetch } from "hooks";

const LogosPage = () => {
  const { data, error, loading } = useShowroomServiceFetch(
    "/organizations/logos"
  );
  const [token, setToken] = useState("");
  const [filterInput, setFilterInput] = useState("");
  const filterInputRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [optionsPerPage] = useState(8);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [filteredLogos, setFilteredLogos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const fileRef = useRef(null);
  const [orgId, setOrgId] = useState("");
  const { getSession } = useContext(AccountContext);
  const indexOfLastOption = currentPage * optionsPerPage;
  const indexOfFirstOption = indexOfLastOption - optionsPerPage;

  useEffect(() => {
    getSession()
      .then((data) => {
        setToken(data.idToken.jwtToken);
        setOrgId(data.idToken.payload["custom:org_id"]);
      })
      .catch((error) => {
        error;
      });
  }, []);

  const acceptableFileNames = ["jpg", "jpeg", "png", "webp"];

  const checkFileName = (name) => {
    const fileChecked = acceptableFileNames.includes(
      name.split(".").pop().toLowerCase()
    );

    return fileChecked;
  };

  const handleFile = async (event) => {
    setIsLoading(true);
    let contentType = "";
    const myFile = event.target.files[0];
    const paths = event.target.value.split("\\");
    const name = paths[2].split(".")[0];
    const url = URL.createObjectURL(myFile);
    if (!myFile) return;

    if (!checkFileName(myFile.name)) {
      ShowAlert({
        title: "Error",
        text: "Invalid File Type!",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
      setIsLoading(false);
      if (null !== fileRef.current) {
        fileRef.current.value = "";
      }
    } else {
      switch (myFile.name) {
        case "jpeg":
        case "jpg":
        case "jfif":
          contentType = "image/jpeg";
          break;
        case "webp":
          contentType = "image/webp";
          break;
        case "png":
          contentType = "image/png";
          break;
        default:
          fileRef.current.value = "";
          break;
      }
      const config = {
        headers: {
          "Content-Type": contentType,
        },
      };
      await AssetService.put(
        `https://api.mantisxr.com/logos/public-upload/${orgId}:${myFile.name}`,
        myFile,
        config
      )
        .then((response) => {
          if (response.status === 200) {
            ShowAlert({
              title: "Success!!",
              text: "You've successfully uploaded a brand image!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
            const newOption = {
              alias: name,
              id: generateUUID(),
              type: "placeholder",
              path: url,
              organization_id: data.id,
            };
            setCurrentOptions([...currentOptions, newOption]);
          }
        })
        .catch((error) => {
          //error
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  useEffect(() => {
    if (!loading) {
      setCurrentOptions(data);
      setFilteredLogos(data);
    }
  }, [loading]);

  useEffect(() => {
    if (filteredLogos?.length > 1) {
      setCurrentOptions(
        filteredLogos.slice(indexOfFirstOption, indexOfLastOption)
      );
    } else {
      setCurrentOptions(data?.slice(indexOfFirstOption, indexOfLastOption));
    }
  }, [data, loading, filteredLogos, currentPage]);

  useEffect(() => {
    const filteredDataByName = logoFilter({
      logos: data,
      nameInput: filterInput,
    });
    setFilteredLogos(filteredDataByName);
  }, [filterInput]);

  return (
    <>
      <div className="main-heading d-flex justify-content-between">
        <div>
          <h3 className="mt-2">2D Assets</h3>
        </div>
        <div className="mt-2">
          {!isLoading ? (
            <>
            <label
              htmlFor="files"
              className="lato-regular btn btn-outline-light me-auto"
            >
              Add 2D Asset
            </label>
              <input
                id="files"
                style={{ display: "none" }}
                type="file"
                ref={fileRef}
                onChange={(event) => handleFile(event)}
              />
            </>
          ) : (
            <div className="lato-regular btn btn-outline-light me-auto">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </div>
          )}
          
        </div>
      </div>
      <div className="main-heading mt-1 d-flex align-items-center justify-content-start">
        <div className="w-50% search-bar">
          <FaSearch
            style={{ marginLeft: "1rem", position: "absolute" }}
            color="white"
          />
          <Form.Control
            className="search-input"
            ref={filterInputRef}
            onChange={(event) => {
              setFilterInput(event.target.value);
            }}
            placeholder={"Search"}
          />
        </div>
      </div>
      <div id="projects-preview" className="koulen-regular mt-3">
        <div id="view-project-cards" className="wrapped-cards">
          {isLoading ? <SpinnerLoader /> : <>
          {!loading && data.length === 0 && (
            <div id="no-projects">
              <h2>You currently do not have any 2D Assets</h2>
              <div>
                  <label
                  htmlFor="files"
                  className="lato-regular btn btn-outline-light me-auto"
                >
                  Add 2D Asset
                </label>
                <input
                  id="files"
                  style={{ display: "none" }}
                  type="file"
                  ref={fileRef}
                  onChange={(event) => handleFile(event)}
                />
                </div>
            </div>
          )}
          {loading ? <SpinnerLoader /> :
            <LogoGrid
              logos={currentOptions}
              setCurrentOptions={setCurrentOptions}
              token={token}
              setSelectedLogo={setSelectedLogo}
              selectedLogo={selectedLogo}
              filterInputRef={filterInputRef}
            />}
          </>}
          
        </div>
      </div>
      {filteredLogos?.length > 1 && (
        <div className="main-heading main-pagination-container">
          <div className="pagination-container">
            <Pagination
              optionsPerPage={optionsPerPage}
              totalOptions={filteredLogos.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LogosPage;
