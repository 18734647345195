/* eslint-disable react/no-unknown-property */
import { useState, useEffect, useMemo } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { TextureLoader, MeshStandardMaterial } from "three";
import { useLoader } from "@react-three/fiber";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

const ProductPreview = ({ textureUrl, geometry, geometryType }) => {
  let texture = null;
  if (textureUrl) {
    texture = useLoader(TextureLoader, textureUrl);
    texture.flipY = false;
  }

  const [model, setModel] = useState(null);

  const meshMaterial = useMemo(() => {
    let meshMaterial = null;
    if (texture) {
      meshMaterial = new MeshStandardMaterial({
        map: texture,
        side: 2,
      });
    }
    return meshMaterial;
  }, [texture]);

  useEffect(() => {
    const loader = new GLTFLoader();
    let isMounted = true;
    if (geometryType === "url") {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath(
        "https://3dmodelsbucket.s3.us-west-2.amazonaws.com/decoder/gltf/"
      );
      loader.setDRACOLoader(dracoLoader);
      loader.load(geometry, (gltf) => {
        if (meshMaterial && isMounted) {
          gltf.scene.children[0].material = meshMaterial;
        }
        setModel(gltf.scene);
      });
    } else if (geometryType === "file") {
      loader.load(URL.createObjectURL(geometry), (gltf) => {
        if (meshMaterial && isMounted) {
          gltf.scene.children[0].material = meshMaterial;
        }
        setModel(gltf.scene);
      });
    }

    return () => {
      isMounted = false;
      setModel(null);
    };
  }, [geometry, meshMaterial, geometryType]);

  return (
    <>
      {meshMaterial && model && (
        <mesh
          position={[0, -1.2, 2.8]}
          rotation={[0, 0, 0]}
          scale={2}
          material={meshMaterial}
        >
          <primitive object={model} />
        </mesh>
      )}
      {!meshMaterial && model && (
        <mesh position={[0, -1.2, 2.8]} rotation={[0, 0, 0]} scale={2}>
          <primitive object={model} />
        </mesh>
      )}
    </>
  );
};

export default ProductPreview;
