import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, PositionSelector, ShowAlert } from "components/globals";
import { DashboardLayout } from "components/layouts";
// import ShowroomService from "services/ShowroomService";
import * as BsIcons from "react-icons/bs";
import { gallery_pos } from "utils/mocks/roomPositions";
import { useShowroomServiceFetch } from "hooks";
import { AccountContext } from "components/providers/Account";
import Service from "services";

const AddProductsToShowroomPage = () => {
  const [showroom, setShowroom] = useState("");
  const [organizationId, setOrganizationId] = useState(null);
  const [token, setToken] = useState(null);
  const [showroomData, setShowroomData] = useState({});
  const [showroomSelected, setShowroomSelected] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [dataToSubmit, setDataToSubmit] = useState({});
  const [tableData, setTableData] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);

  const { getSession } = useContext(AccountContext);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useLayoutEffect(() => {
    setIsLoading(true);

    getSession().then((data) => {
      setOrganizationId(data.idToken.payload["custom:org_id"]);
      setToken(data.idToken.jwtToken);
    });

    const showroom = searchParams.get("showroom");
    setShowroom(showroom);

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  const { data: globalProducts, error: globalError } = useShowroomServiceFetch(
    `/organizations/${organizationId}/products`
  );

  const { data, error, loading } = useShowroomServiceFetch(
    `/products/showroom/${showroom}`
  );

  useEffect(() => {
    if (showroom && data && globalProducts) {
      const { models } = data;
      const filtered = globalProducts.filter(
        (product) => product.status_id === 1 || product.status_id === 4
      );

      setTableData(models);

      const productsAttachment = [];

      for (let index = 0; index < filtered.length; index++) {
        const element = filtered[index];

        if (
          element.attachment.length > 0 ||
          element.status_id === 1 ||
          element.status_id === 4
        ) {
          productsAttachment.push(element);
        }
      }
      setShowroomData(data);
      setProducts(productsAttachment);
    }

    if (error) {
      ShowAlert({
        title: "Ooppss...!!",
        text: error,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [data, error, loading, globalProducts, globalError]);

  useEffect(() => {
    const tableData = [];

    if (showroomSelected?.models) {
      for (let index = 0; index < gallery_pos.length; index++) {
        const positionsInShowroom = gallery_pos[index];

        for (let indx = 0; indx < showroomSelected.models.length; indx++) {
          const productPosition = showroomSelected.models[indx];

          if (
            JSON.stringify(positionsInShowroom.id) ===
            JSON.stringify(productPosition.position)
          ) {
            tableData.push({
              position: positionsInShowroom.name,
              storefront: productPosition.storefront,
              product: productPosition.name,
              productId: productPosition.id,
            });
          }
        }
      }
    }

    setTableData(tableData);
  }, [showroomSelected]);

  const handleProductSelected = (props) => {
    const productsByPosition = [];
    for (let index = 0; index < props.length; index++) {
      const element = props[index];
      productsByPosition.push({
        productId: element.product.id,
        storefrontId: element.storefront.id,
        serial_number: element.serial_number,
        location: element.location.toString(),
        old_location: element.old_location.toString(),
      });
    }

    const body = {
      alias: showroomData.alias,
      models: productsByPosition,
      template: showroomData.template,
    };

    setDataToSubmit(body);
  };

  const handleSubmit = () => {
    setButtonLoader(true);

    let validToken = "";
    const body = dataToSubmit;

    if (token) {
      validToken = token;
    }

    const config = {
      headers: {
        Authorization: validToken,
      },
    };

    Service.post("/showrooms/batch-update-models", body, config)
      .then((response) => {
        if (response.status === 200) {
          ShowAlert({
            title: "Great...!!!",
            text: "You have made changes in the Showroom. Do you want to preview your changes?",
            icon: "success",
            showConfirmButton: true,
            confirmButtonText: "Yes",
            showDenyButton: true,
            denyButtonText: "No",
          }).then((result) => {
            if (result.isDenied) {
              navigate("/dashboard/myShowrooms");
            } else {
              setButtonLoader(false);
              window.open(
                `https://metaverse-dev.mantisxr.com/dynamic-showrooms/${showroom}`,
                "_blank"
              );
            }
          });
        }

        setButtonLoader(false);
      })
      .catch((error) => {
        if (error) {
          ShowAlert({
            title: "Ooppss...!!!",
            text: `${error.response.data.message}`,
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        setButtonLoader(false);
      });
  };

  return (
    <DashboardLayout loader={isLoading}>
      <Row className="mb-5">
        <Col lg={11}>
          <h3>Add products to {showroom}</h3>
        </Col>
        <Col lg={1}>
          <button
            onClick={() => navigate(-1)}
            style={{ border: "none", background: "none" }}
          >
            <BsIcons.BsFillArrowLeftSquareFill size={30} />
          </button>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col lg={12}>
          <PositionSelector
            onProductSelected={(product) => handleProductSelected(product)}
            products={products}
            position="Position 1"
            tableData={tableData}
          />
        </Col>
      </Row>
      {dataToSubmit?.models?.length > 0 && (
        <Row>
          <Button isLoading={buttonLoader} onClick={handleSubmit}>
            Submit Changes
          </Button>
        </Row>
      )}
    </DashboardLayout>
  );
};

export default AddProductsToShowroomPage;
