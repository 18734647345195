import { GoBack } from "./GoBack";
import { SearchBar } from "./SearchBar";

// const tagsPlaceholder = ["Homes", "Stadiums", "Indoor", "Outdoor"];
// const productTagsPlaceholder = ["Accessories", "T-Shirts", "Hats", "Hoodies"];

export const Header = ({ currentlyViewed, handleBackButton }) => {
  return (
    <>
      <GoBack onClick={handleBackButton} currentlyViewed={currentlyViewed} />
      <SearchBar />
      <div className="mx-5 mt-2">
        <h5 className="koulen-regular">
          {currentlyViewed ? "3d models" : "Free Venues"}
        </h5>
      </div>
    </>
  );
};
