import { Row } from "react-bootstrap";
import { DashboardLayout } from "components/layouts";
import ModelForm from "components/productOptions/ModelForm";

const AddModelPage = () => {
  return (
    <DashboardLayout>
      <Row>
        <ModelForm />
      </Row>
    </DashboardLayout>
  );
};

export default AddModelPage;
