/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable react/no-unknown-property */
import { FaEllipsisH, FaUpload } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { EffectComposer, SelectiveBloom } from "@react-three/postprocessing";
import { useShowroomServiceFetch } from "hooks";
import { Canvas } from "@react-three/fiber";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef, Suspense, useContext } from "react";

import Scene from "components/globals/Scene";
import { AccountContext } from "components/providers/Account";
import ShowroomService from "services/ShowroomService";
import ProductCards from "components/CreateProject/ProductCards";
import { Hotspot, ShowAlert } from "components/globals";
import EditProductPreview from "components/globals/EditProductPreview";
import Shift from "controls/Shift";
import { ProductPlacementModal } from "components/modals/ProductPlacementModal";
import { Instructions } from "./components/Instructions";
import { ModalSelectedVenue } from "./components/ModalSelectedVenue";
import { Header } from "./components/Header/Header";
import { ProductTooltip } from "./components/ProductTooltip";

const CreateProjectPage = () => {
  const [onSceneCreated, setOnSceneCreated] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [productsInPositions, setProductsInPositions] = useState([]);
  const { data: venues } = useShowroomServiceFetch("/venues");
  const [token, setToken] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentlyViewed, setCurrentlyViewed] = useState(null);
  const [location, setLocation] = useState(null);
  const [hovered, onHover] = useState(null);
  const selected = hovered ? [hovered] : undefined;
  const nameRef = useRef(null);
  const [modalMode, setModalMode] = useState(null);
  const [placementModalOpen, setPlacementModalOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [draggedProduct, setDraggedProduct] = useState(false);
  const tooltipRef = useRef(null);
  const navigate = useNavigate();

  const { getSession } = useContext(AccountContext);

  useEffect(() => {
    if (currentlyViewed?.positions?.length > 0) {
      setProductsInPositions(currentlyViewed.positions);
    } else {
      setProductsInPositions([]);
    }
  }, [currentlyViewed]);

  useEffect(() => {
    getSession().then((response) => {
      const orgId = response.idToken.payload["custom:org_id"];
      const token = response.idToken.jwtToken;
      setToken(token);

      ShowroomService.get(`/organizations/${orgId}/models`, {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          if (res?.data?.length > 0) {
            const products = res.data;
            setProductsData(products);
          } else {
            setProductsData([]);
          }
        })
        .catch();
    });
  }, []);

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const handleDrop = () => {
    setDraggedProduct(false);
    // setPlacementModalOpen(true);
    // setModalMode("buttons");
  };

  const handleModalPlacement = (modalPlacement) => {
    setPlacementModalOpen(modalPlacement);
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const updatedProducts = productsInPositions.filter(
      (product) => product?.product_data?.id
    );

    const data = {
      name: nameRef.current?.value,
      products: updatedProducts,
      venue: currentlyViewed,
    };

    const config = {
      headers: {
        Authorization: token,
      },
    };

    ShowroomService.post("/showrooms/venue", data, config)
      .then((response) => {
        ShowAlert({
          title: "Success...!!!",
          text: "Your project has been created!",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
        navigate("/dashboard/myShowrooms");
      })
      .catch();
  };

  const handleMouseEnter = (venueData) => {
    setSelectedVenue(venueData);
  };

  const addProductToPosition = (location) => {
    setSelectedLocation(location);
    const prodInPositions = productsInPositions.map((product) => {
      if (product.location === location) {
        return {
          ...product,
          product_data: selectedProduct,
        };
      } else {
        return product;
      }
    });
    setProductsInPositions(prodInPositions);
  };

  const handleMouseLeave = () => {
    setSelectedVenue(null);
  };

  const handleViewChange = () => {
    setCurrentlyViewed(selectedVenue);
  };

  const handleOnSceneCreated = () => {
    setOnSceneCreated(true);
  };

  const handleBackButton = () => {
    if (currentlyViewed) {
      setCurrentlyViewed(null);
      setOnSceneCreated(false);
    } else {
      navigate("/dashboard/myShowrooms");
    }
  };

  const handleModalMode = (mode) => {
    setModalMode(mode);
  };

  const handleProductPosition = (location) => {
    addProductToPosition(location);
  };

  const handleSelectedProduct = (option) => {
    setSelectedProduct(option);
  };

  const shouldRenderProductPositions = () => {
    return productsInPositions?.length > 0 && onSceneCreated;
  };

  return (
    <div id="create-project-page" className="d-flex">
      <div id="venue-select">
        <Header
          currentlyViewed={currentlyViewed}
          handleBackButton={handleBackButton}
        />
        <div id="filtered-venues">
          {!currentlyViewed &&
            venues?.length > 0 &&
            venues?.map((venue) => {
              return (
                <div
                  className="venue-filter-card m-1 d-flex justify-content-end"
                  key={venue.id}
                  style={{
                    backgroundImage: `url("${venue?.preview_image}")`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="ellpisis-icon m-1">
                    <FaEllipsisH
                      color="white"
                      onMouseEnter={() => handleMouseEnter(venue)}
                    />
                  </div>
                </div>
              );
            })}
          
          {/* {!currentlyViewed && (
            <div
              onClick={() => {
                setSelectedVenue("upload");
              }}
              className="btn btn-outline-light venue-upload-card m-1 d-flex flex-column justify-content-end align-items-center"
            >
              <FaUpload size={20} className="mb-3" />
              <p className="lato-regular">Upload Venue</p>
            </div>
          )} */}
        </div>
        {currentlyViewed && productsData?.length > 0 && (
            <ProductCards
              products={productsData}
              addProductToPosition={addProductToPosition}
              setSelectedProduct={setSelectedProduct}
              selectedLocation={selectedLocation}
              setDraggedProduct={setDraggedProduct}
            />
          )}
        {currentlyViewed && (
          <Form
            onSubmit={onSubmit}
            className="d-flex flex-column align-items-center"
          >
            <Form.Group controlId="name">
              <Form.Control
                required
                ref={nameRef}
                type="text"
                placeholder="Project Name"
              />
            </Form.Group>
            <button
              id="project-sumbit-btn"
              type="submit"
              className="btn btn-outline-light lato-regular mt-2 px-4"
            >
              Create Project
            </button>
          </Form>
        )}
      </div>
      <div
        id="venue-preview"
        className="d-flex justify-content-center align-items-center"
      >
        {selectedVenue && (
          <div
            className={"tooltip-detail tooltip-venue"}
            style={{ zIndex: 20, position: "absolute", left: "4%", top: "74%" }}
          >
            {" "}
            {selectedVenue === "upload" ? (
              <div className="w-100 h-100 px-1 d-flex flex-column justify-content-evenly">
                <h5 className="koulen-regular my-1">Upload Venue</h5>
                <Form.Group
                  className="my-1 d-flex align-items-center"
                  controlId="name"
                >
                  <Form.Control
                    required
                    className="w-80"
                    type="text"
                    placeholder="Name"
                  />
                </Form.Group>
                <Form.Group className="my-1">
                  <Form.Control type="file" placeholder="Select file" />
                </Form.Group>
                <div
                  id="venue-upload-submit"
                  className="btn btn-outline-dark lato-regular"
                >
                  Submit
                </div>
              </div>
            ) : (
              <ModalSelectedVenue
                venue={selectedVenue}
                onMouseLeave={handleMouseLeave}
                onViewChange={handleViewChange}
              />
            )}
          </div>
        )}
        {selectedProduct && currentlyViewed && (
          <ProductTooltip
            positions={currentlyViewed.positions}
            selectedProduct={selectedProduct}
            onClick={handleProductPosition}
            onMouseLeave={handleSelectedProduct}
          />
        )}
        {currentlyViewed?.scene?.length > 0 ? (
          <div
            id="venue-canvas"
            onDragOver={(event) => allowDrop(event)}
            onDrop={handleDrop}
          >
            <Canvas
              frameloop="demand"
              gl={{
                antialias: true,
                stencil: false,
                powerPreference: "high-performance",
                precision: "lowp",
                toneMapping: 5,
                toneMappingExposure: 1,
              }}
            >
              <Suspense fallback={null}>
                <Scene
                  texture={currentlyViewed?.scene}
                  onSceneCreated={handleOnSceneCreated}
                />
                <EffectComposer autoClear={false}>
                  <SelectiveBloom selection={selected} />
                </EffectComposer>
              </Suspense>
              {shouldRenderProductPositions() &&
                productsInPositions.map((position) => {
                  if (position?.product_data?.path?.length > 0) {
                    return (
                      <EditProductPreview
                        key={position.product_data.id}
                        onHover={onHover}
                        productLocation={position.location}
                        geometry={position.product_data.path}
                        geometryType="url"
                        textureUrl={null}
                        position={position.position}
                        rotation={
                          position.product_data?.rotation || position.rotation
                        }
                        setProductsInPositions={setProductsInPositions}
                        productsInPositions={productsInPositions}
                        location={position.location}
                        scale={1}
                      />
                    );
                  } else {
                    return (
                      <Hotspot
                        key={position.location}
                        onModalMode={handleModalMode}
                        onPlacementModal={handleModalPlacement}
                        position={position}
                        onHover={onHover}
                        setLocation={setLocation}
                        addProductToPosition={addProductToPosition}
                        draggedProduct={draggedProduct}
                        setDraggedProduct={setDraggedProduct}
                      />
                    );
                  }
                })}
              <>
                <ambientLight intensity={2.5} />
                <Shift
                  selectedProduct={
                    selectedLocation || location
                      ? productsInPositions[
                          parseInt(selectedLocation ?? location) - 1
                        ]
                      : {
                          viewing_position: currentlyViewed?.initial_position,
                          viewing_rotation: currentlyViewed?.initial_rotation,
                          position: currentlyViewed?.initial_position,
                        }
                  }
                />
              </>
            </Canvas>
          </div>
        ) : (
          <Instructions />
        )}
      </div>
      <ProductPlacementModal
        placementModalOpen={placementModalOpen}
        onPlacementModal={handleModalPlacement}
        selectedProduct={selectedProduct}
        addProductToPosition={addProductToPosition}
        location={location}
        modalMode={modalMode}
        productsInPositions={productsInPositions}
      />
    </div>
  );
};

export default CreateProjectPage;
