import { useEffect, useRef, useState } from "react";
import { Pagination, ShowAlert } from "components/globals";
import { Form, Table } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { productsFilter } from "helpers";
import { Spinner } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import ShowroomService from "services/ShowroomService";
import ModelForm from "./ModelForm";
import AssetService from "services/AssetService";

const ShopifyProductsTable = ({
  organization,
  storefront,
  token,
  mode,
  template,
  showroom,
  products,
  orgProductData,
  setOrgProductData,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [optionsPerPage] = useState(5);
  const [filterInput, setFilterInput] = useState("");
  const filterInputRef = useRef(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productId, setProductId] = useState("");
  const indexOfLastOption = currentPage * optionsPerPage;
  const indexOfFirstOption = indexOfLastOption - optionsPerPage;
  const [isLoading, setIsLoading] = useState(false);
  const [currentOptions, setCurrentOptions] = useState(
    products.slice(indexOfFirstOption, indexOfLastOption)
  );

  useEffect(() => {
    if (filteredProducts.length > 0) {
      setCurrentOptions(
        filteredProducts.slice(indexOfFirstOption, indexOfLastOption)
      );
    } else {
      setCurrentOptions(products.slice(indexOfFirstOption, indexOfLastOption));
    }
  }, [filteredProducts, currentPage]);

  useEffect(() => {
    setCurrentOptions(products.slice(indexOfFirstOption, indexOfLastOption));
  }, [products]);

  const fileRef = useRef();

  const acceptableFileName = "glb";

  const checkFileName = (name) => {
    const fileChecked = acceptableFileName.includes(
      name.split(".").pop().toLowerCase()
    );

    return fileChecked;
  };

  const handleFile = async (event) => {
    setIsLoading(true);
    const myFile = event.target.files[0];
    const paths = event.target.value.split("\\");
    const name = paths[2].split(".")[0];
    if (!myFile) return;

    if (!checkFileName(myFile.name)) {
      ShowAlert({
        text: "Invalid File Type!",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });

      if (null !== fileRef.current) {
        fileRef.current.value = "";
      }

      return;
    }
    const body = {
      storefrontId: storefront.id,
      productId: productId,
      name,
      orgId: organization,
      type: "model",
    };

    AssetService.put("assetsUpload", body)
      .then((response) => {
        fetch(response.data.uploadURL, {
          method: "PUT",
          body: myFile,
          headers: {
            "Content-Type": "model/gltf-binary",
          },
        }).then((responseS3) => {
          if (responseS3.status === 200) {
            ShowAlert({
              title: "Success!!",
              text: "3D Model added successfully!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        });
      })
      .catch((error) => {
        //error
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddProduct = (product) => {
    setIsLoading(true);
    const serialNumber = product.node.id.split("/");
    const images = [];
    product.node.images.edges.forEach((image) => {
      images.push({ url: image.node.src });
    });
    const body = [
      {
        serial_number: serialNumber[serialNumber.length - 1],
        name: product.node.title,
        description: product.node.description,
        products_urls: { url: product.node.onlineStoreUrl },
        products_data: images,
        attachment: "",
        org_id: organization,
        status_id: 3,
        storefront_id: storefront.id,
      },
    ];

    const config = {
      headers: {
        Authorization: token,
      },
    };

    ShowroomService.post("products", body, config)
      .then((response) => {
        const { message, products } = response.data;
        if (response.status === 200) {
          if (message === "Product already created") {
            ShowAlert({
              title: "This Product already exists",
              icon: "warning",
              text: "Please, try with another product",
              showConfirmButton: false,
              timer: 2000,
            });
          }
          if (message === "Products created successfully") {
            orgProductData.set(products[0].serial_number, products[0]);
            setOrgProductData(orgProductData);
            ShowAlert({
              title: "Success...!!!",
              text: "The product has been added successfully!",
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
            });
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const filteredDataByName = productsFilter({
      products: products,
      nameInput: filterInput,
    });
    setFilteredProducts(filteredDataByName);
  }, [filterInput]);

  return (
    <>
      {currentOptions.length > 0 && (
        <div className="main-heading mt-3">
          <div className="w-50% search-bar">
            <FaSearch
              style={{ marginLeft: "1rem", position: "absolute" }}
              color="white"
            />
            <Form.Control
              className="search-input"
              ref={filterInputRef}
              onSubmit={handleSubmit}
              onChange={(event) => {
                setFilterInput(event.target.value);
              }}
              placeholder={"Search"}
            />
          </div>
        </div>
      )}
      <div className="main-heading d-flex flex-column">
        <Table id="shopify-table" className="table table-dark">
          {currentOptions.length > 0 && (
            <thead>
              <tr>
                <th className="text-center">Image</th>
                <th className="text-center">Id</th>
                <th className="text-center">Title</th>
                <th className="text-center">Manage</th>
              </tr>
            </thead>
          )}
          <tbody>
            {currentOptions.length > 0 &&
              currentOptions.map((prod) => {
                const idNumber = prod.node.id.split("/");
                const productOption = orgProductData?.get(idNumber[4]);
                return (
                  <tr key={prod.node.id}>
                    <td className="text-center">
                      <img
                        style={{ height: "90px", width: "auto" }}
                        className="img-thumbnail sf__table_image"
                        src={prod.node.images.edges[0].node.src}
                      />
                    </td>
                    <td>
                      <div className="shopify-text-wrapper">{idNumber[4]}</div>
                    </td>
                    <td>
                      <div className="shopify-text-wrapper">
                        {prod.node.title}
                      </div>
                    </td>
                    <td className="h-100 d-flex align-items-center justify-content-center">
                      {productOption ? (
                        <div
                          className="d-flex flex-column align-items-center"
                          onMouseOver={() => setProductId(idNumber[4])}
                        >
                          <p className="live-text">Added</p>
                          <>
                            {!isLoading ? (
                              <label
                                htmlFor="files"
                                className="lato-regular btn btn-outline-light me-auto"
                              >
                                Add 3D Model
                              </label>
                            ) : (
                              <div className="lato-regular btn btn-outline-light me-auto">
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                Loading...
                              </div>
                            )}
                            <input
                              id="files"
                              style={{ display: "none" }}
                              type="file"
                              ref={fileRef}
                              onChange={(event) => handleFile(event)}
                            />
                          </>
                        </div>
                      ) : (
                        <>
                          {isLoading ? (
                            <button
                              disabled
                              className="btn btn-dark border border-light"
                            >
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              Loading...
                            </button>
                          ) : (
                            <button
                              className="btn btn-dark border border-light"
                              onClick={() => handleAddProduct(prod)}
                            >
                              Add Product
                            </button>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {selectedProduct && (
          <div className="modal-model-form d-flex">
            <ModelForm
              org_id={organization}
              serial_number={selectedProduct.serial_number}
              token={token}
              origin={mode}
            />
            <IoMdClose
              color="black"
              className="mt-1 mx-1"
              size={20}
              onClick={() => setSelectedProduct(null)}
            />
          </div>
        )}
        {products.length > 1 && (
          <div className="mb-auto main-pagination-container d-flex justify-content-center">
            <div className="pagination-container">
              <Pagination
                optionsPerPage={optionsPerPage}
                totalOptions={products.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ShopifyProductsTable;
