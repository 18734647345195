import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { DashboardLayout } from "components/layouts";
import {
  // ProductsFromSpreadSheet,
  GeometryForm,
} from "components/productOptions";

const AddGeometryPage = () => {
  const [organization, setOrganization] = useState("");
  const [showroom, setShowroom] = useState("");
  const [template, setTemplate] = useState("");

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (
      searchParams.get("organization") &&
      searchParams.get("showroom") &&
      searchParams.get("template")
    ) {
      setOrganization(searchParams.get("organization"));
      setTemplate(searchParams.get("template"));

      const data = searchParams
        .get("showroom")
        ?.toLowerCase()
        .replace(/ /g, "-");

      setShowroom(data);
    }
  }, []);

  return (
    <DashboardLayout>
      <Row>
        {/* <Tabs
          defaultActiveKey="shopify"
          id="justify-product-tab"
          className="mb-3"
          variant="tabs"
        >
          <Tab eventKey="shopify" title="Shopify"> */}
        <GeometryForm
          template={template}
          organization={organization}
          showroom={showroom}
        />
        {/* </Tab>
          <Tab eventKey="file" title="File">
            <ProductsFromSpreadSheet
              organization={organization}
              showroom={showroom}
            />
          </Tab>
        </Tabs> */}
      </Row>
    </DashboardLayout>
  );
};

export default AddGeometryPage;
