import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { RegistrationLayout } from "../components/layouts";
import UserPool from "../config/UserPool";
import jwt from "jwt-decode";
import { ShowAlert } from "../components/globals";
import ShowroomService from "services/ShowroomService";

const SignupPage = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [org_alias, setOrgAlias] = useState("");
  const [org_name, setOrgName] = useState("");
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [invited_by, setInvited_by] = useState("");
  const [created_at, setCreated_at] = useState("");
  const [permission, setPermission] = useState("");

  const passwordRef = useRef < HTMLInputElement > null;
  const repeatPasswordRef = useRef < HTMLInputElement > null;

  const { token } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const decodeToken = jwt(token);
    const currentDate = new Date();

    if (decodeToken.exp * 1000 < currentDate.getTime()) {
      setTokenIsValid(false);
    } else {
      setTokenIsValid(true);
      setEmail(decodeToken.email);
      setName(decodeToken.name);
      setOrgAlias(decodeToken.org_alias);
      setOrgName(decodeToken.org_name);
      setInvited_by(decodeToken.sender_email);
      setCreated_at(decodeToken.created_at);
      setPermission(decodeToken.permission);
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (null !== passwordRef.current && null !== repeatPasswordRef.current) {
      if (repeatPasswordRef.current.value === passwordRef.current.value) {
        const attributeList = [];

        const attributeName = new CognitoUserAttribute({
          Name: "name",
          Value: name,
        });

        const attributeOrgId = new CognitoUserAttribute({
          Name: "custom:org_id",
          Value: org_alias,
        });

        const attributeOrgName = new CognitoUserAttribute({
          Name: "custom:org_name",
          Value: org_name,
        });

        const attributePermission = new CognitoUserAttribute({
          Name: "custom:permission",
          Value: permission,
        });

        attributeList.push(attributeName);
        attributeList.push(attributeOrgId);
        attributeList.push(attributeOrgName);
        attributeList.push(attributePermission);

        const acceptedUser = {
          email,
          name,
          org_alias,
          org_name,
          accepted: true,
          created_at,
          updated_at: new Date().toUTCString(),
          invited_by,
          permission,
        };

        UserPool.signUp(
          email,
          passwordRef.current.value,
          attributeList,
          [],
          (error, data) => {
            if (error) {
              ShowAlert({
                title: "Oopsss...!",
                text: `${error.message}`,
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
              });
            } else {
              ShowAlert({
                title: `Welcome to MANTIS XR user ${data?.user.getUsername()}`,
                text: "Please, check your email for the verification code",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
              })
                .then(() => {
                  ShowroomService.put("/invite-user", acceptedUser);
                })
                .then(() => {
                  navigate("/login");
                });
            }
          }
        );
      } else {
        ShowAlert({
          title: "Password are not the same",
          text: "Make sure you have the same password in both fields",
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };
  return tokenIsValid ? (
    <RegistrationLayout title="Change your password to continue!">
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            ref={passwordRef}
            type="password"
            placeholder="Enter your password"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="repeatPassword">
          <Form.Label>Repeat Password</Form.Label>
          <Form.Control
            required
            ref={repeatPasswordRef}
            type="password"
            placeholder="Repeat your password"
          />
        </Form.Group>

        <div className="text-center mt-3">
          <Button variant="primary" type="submit">
            Change Password
          </Button>
        </div>
      </Form>
    </RegistrationLayout>
  ) : (
    <div>Token is no longer valid, Please contact your Administrator</div>
  );
};

export default SignupPage;