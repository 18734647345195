import { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import Button from "./Button";
import ShowAlert from "./Alert";

const PositionSelector = ({
  position,
  products,
  onProductSelected,
  tableData,
}) => {
  const [positions, setPositions] = useState([
    { value: "1", disable: false },
    { value: "2", disable: false },
    { value: "3", disable: false },
    { value: "4", disable: false },
    { value: "5", disable: false },
    { value: "6", disable: false },
    { value: "7", disable: false },
    { value: "8", disable: false },
    { value: "9", disable: false },
    { value: "10", disable: false },
    { value: "11", disable: false },
    { value: "12", disable: false },
  ]);
  const [positionSelected, setPositionSelected] = useState("");
  const [productsRef, setProductsRef] = useState({});
  const [productsToAddToShowroom, setProductsToAddToShowroom] = useState([]);
  const [productsToDeleteFromShowroom, setProductsToDeleteFromShowroom] =
    useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [productsInTheShowroom, setProductsInTheShowroom] = useState([]);
  const [productSelected, setProductSelected] = useState({});
  const [displayPositions, setDisplayPositions] = useState(false);

  useEffect(() => {
    if (!tableData) {
      ShowAlert({
        title: "Reloading...",
        text: "Please wait while the page is reloading",
        icon: "info",
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        window.location.reload();
      });
    }

    setProductsInTheShowroom(tableData);
  }, [tableData, products]);

  useEffect(() => {
    setDisplayPositions(false);
    const positionsCopy = positions;
    const productsRef = new Map();
    for (let index = 0; index < productsInTheShowroom.length; index++) {
      const element = productsInTheShowroom[index];

      for (let indx = 0; indx < positions.length; indx++) {
        const elementPositions = positions[indx];

        if (elementPositions.value === element.location) {
          elementPositions.disable = true;
        }
      }
    }

    setPositions(positionsCopy);

    setTimeout(() => {
      setDisplayPositions(true);
    }, 4000);
  }, [productsInTheShowroom]);

  useEffect(() => {
    onProductSelected([
      ...productsToAddToShowroom,
      ...productsToDeleteFromShowroom,
    ]);
  }, [productsToAddToShowroom, productsToDeleteFromShowroom]);

  const handleAddProduct = () => {
    const parsedProduct = JSON.parse(productSelected);
    const productToAdd = {
      ...parsedProduct,
      product: parsedProduct,
      storefront: parsedProduct.storefront,
      location: positionSelected,
      old_location: "",
    };

    setPositions((current) => {
      current.filter((position) => {
        if (position.value === positionSelected.toString()) {
          return (position.disable = true);
        }
      });
      return current;
    });

    setProductsToAddToShowroom([...productsToAddToShowroom, productToAdd]);

    setDisplayPositions(false);
    setTimeout(() => {
      setDisplayPositions(true);
    }, 2000);
  };

  const itemDelete = (item, circuit) => {
    const parsedProduct = JSON.parse(item);

    if (circuit === "toBeAdded") {
      setProductsToAddToShowroom((current) =>
        current.filter((prod) => {
          return prod.location !== parsedProduct.location;
        })
      );

      setPositions((current) => {
        current.filter((position) => {
          if (position.value === parsedProduct.location.toString()) {
            return (position.disable = false);
          }
        });
        return current;
      });

      setDisplayPositions(false);
    } else if (circuit === "alreadyAdded") {
      const productTodelete = {
        serial_number: parsedProduct.product.serial_number,
        storefront: parsedProduct.product.storefront,
        product: parsedProduct.product,
        location: "",
        old_location: parsedProduct.location,
      };

      const newList = [];

      productsInTheShowroom.map((prod) => {
        if (prod.product_id !== parsedProduct.product_id) {
          newList.push(prod);
        }
      });

      setPositions((current) => {
        current.filter((position) => {
          if (position.value === parsedProduct.location.toString()) {
            return (position.disable = false);
          }
        });
        return current;
      });

      setProductsInTheShowroom(newList);

      setDeleteList([...deleteList, parsedProduct]);

      setProductsToDeleteFromShowroom([
        ...productsToDeleteFromShowroom,
        productTodelete,
      ]);
    }
    setTimeout(() => {
      setDisplayPositions(true);
    }, 2000);
  };

  const itemRecover = (item) => {
    const parsedProduct = JSON.parse(item);

    for (let index = 0; index < positions.length; index++) {
      const element = positions[index];

      if (element.value === parsedProduct.location) {
        if (element.disable) {
          ShowAlert({
            title: "Ooppss...!!",
            text: "The position is already occupied",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          setDeleteList((current) => {
            return current.filter((prod) => {
              return prod.product_id !== parsedProduct.product_id;
            });
          });
          setProductsInTheShowroom([...productsInTheShowroom, parsedProduct]);
        }
      }
    }
  };

  return (
    <>
      <Row>
        <Col lg={6}>
          <Form.Group>
            <Form.Select
              onChange={(event) => {
                if (event.target.value === "Product") {
                  setProductSelected("");
                } else {
                  setProductSelected(event?.target.value);
                }
              }}
            >
              <option>Product</option>
              {products.map((product) => {
                return (
                  <option value={JSON.stringify(product)} key={product.id}>
                    {product.name}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group>
            <Form.Select
              onChange={(event) => {
                if (event.target.value === "Position") {
                  setPositionSelected("");
                } else {
                  setPositionSelected(JSON.parse(event?.target.value));
                }
              }}
            >
              <option>Position</option>
              {displayPositions &&
                positions.map((position) => {
                  return (
                    <option
                      value={position.value}
                      key={position.value}
                      disabled={position.disable}
                    >
                      Position {position.value}
                    </option>
                  );
                })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Button onClick={handleAddProduct}>Add Product</Button>
      </Row>
      {productsToAddToShowroom?.length > 0 ? (
        <>
          <Row>
            <Col>
              <h3 className="mb-5 mt-5">Products to be added to showroom</h3>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={12}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Position</th>
                    <th>Name</th>
                    <th>Product ID</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {productsToAddToShowroom.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.location}</td>
                        <td>{item.name}</td>
                        <td>{item?.serial_number || index}</td>
                        <td className="align-middle text-center">
                          <button
                            style={{ border: "none", background: "none" }}
                            onClick={() =>
                              itemDelete(JSON.stringify(item), "toBeAdded")
                            }
                          >
                            <FaIcons.FaRegTrashAlt size={25} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : null}
      {productsInTheShowroom?.length > 0 ? (
        <>
          <Row>
            <Col>
              <h3 className="mb-5 mt-5">Products already in the showroom</h3>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={12}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Position</th>
                    <th>Name</th>
                    <th>Product ID</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {productsInTheShowroom.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.location}</td>
                        <td>{item.product.name}</td>
                        <td>{item.product.serial_number}</td>
                        <td className="align-middle text-center">
                          <button
                            style={{ border: "none", background: "none" }}
                            onClick={() =>
                              itemDelete(JSON.stringify(item), "alreadyAdded")
                            }
                          >
                            <FaIcons.FaRegTrashAlt size={25} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : null}
      {deleteList?.length > 0 ? (
        <>
          <Row>
            <Col>
              <h3 className="mb-5 mt-5">
                Products to be deleted from the showroom
              </h3>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={12}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Old Position</th>
                    <th>Name</th>
                    <th>Product ID</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {deleteList.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.location}</td>
                        <td>{item.product.name}</td>
                        <td>{item.product.serial_number}</td>
                        <td className="align-middle text-center">
                          <button
                            style={{ border: "none", background: "none" }}
                            onClick={() => itemRecover(JSON.stringify(item))}
                          >
                            <RiIcons.RiArrowGoBackFill size={25} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};

export default PositionSelector;
