import { createContext, useState } from "react";

const NavContext = createContext(null);

const NavProvider = (props) => {
  const [sidebar, setSidebar] = useState(false);

  const handleNav = () => {
    setSidebar(!sidebar);
  };

  return (
    <NavContext.Provider value={{ sidebar, handleNav }}>
      {props.children}
    </NavContext.Provider>
  );
};

export { NavProvider, NavContext };
