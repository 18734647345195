/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";

import { BsBagPlusFill } from "react-icons/bs";
import { GoPencil } from "react-icons/go";

const ShowroomModal = ({
  showroomModalHandler,
  showroomSelected,
  modalHandler,
}) => {
  const [showroomModal, setShowroomModal] = useState(false);
  const navigate = useNavigate();

  const handleEdit = (showroom) => {
    navigate(`/dashboard/add-products-to-showroom?showroom=${showroom.alias}`);
  };

  const handleShowroomView = (id) => {
    window.open(
      `${process.env.REACT_APP_METAVERSE_URL}/dynamic-showrooms/${id}`,
      "_blank"
    );
  };

  useEffect(() => {
    if (showroomModalHandler) {
      setShowroomModal(true);
    } else {
      setShowroomModal(false);
    }
  }, [showroomModalHandler]);

  const showroomModalHandleClose = () => {
    setShowroomModal(false);

    modalHandler(false);
  };

  return (
    <Modal
      id="showroom-modal"
      show={showroomModal}
      onHide={showroomModalHandleClose}
      centered
    >
      <Modal.Header>
        <Modal.Title className="koulen-regular ms-3 mt-2">
          {showroomSelected?.project?.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="showroom-modal-list">
          <div className="btn btn-outline-dark showrom-modal-item my-2 lato-regular">
            <GoPencil className="me-2" />
            Edit Project
          </div>
          <div
            className="btn btn-outline-dark showrom-modal-item my-2 lato-regular"
            onClick={() => handleEdit(showroomSelected.project)}
          >
            <BsBagPlusFill className="me-2" />
            Add Products
          </div>
          <div
            className="btn btn-outline-dark showrom-modal-item my-2 lato-regular"
            onClick={() => handleShowroomView(showroomSelected.project.alias)}
          >
            <FaExternalLinkAlt className="me-2" />
            Open in a new tab
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShowroomModal;
