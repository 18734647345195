import { NavLink } from "react-router-dom";
import * as Ionicons5 from "react-icons/io5";
// import * as Vsc from "react-icons/vsc";

const UserSidebar = () => {
  return (
    <>
      <li className="nav-menu__text">
        <NavLink to="/dashboard/myShowrooms">
          <Ionicons5.IoStorefrontOutline />
          <span>My Projects</span>
        </NavLink>
      </li>
      <li className="nav-menu__text">
        <NavLink to="/dashboard/showrooms">
          <Ionicons5.IoStorefrontOutline />
          <span>Create Project</span>
        </NavLink>
      </li>
      <li className="nav-menu__text">
        <NavLink to="/dashboard/storefront">
          <Ionicons5.IoStorefrontSharp />
          <span>Add Online Store Provider</span>
        </NavLink>
      </li>
      <li className="nav-menu__text">
        <NavLink to="/dashboard/products">
          <Ionicons5.IoShirtOutline />
          <span>My Products</span>
        </NavLink>
      </li>
      <li className="nav-menu__text">
        <NavLink to="/dashboard/upload-products">
          <Ionicons5.IoShirtOutline />
          <span>Add Products</span>
        </NavLink>
      </li>
    </>
  );
};

export default UserSidebar;
