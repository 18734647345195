import { useContext, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Button, ShowAlert } from "../globals";
import { AccountContext } from "../providers/Account";

const PasswordChange = () => {
  const oldPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmNewPasswordRef = useRef(null);

  const { getSession } = useContext(AccountContext);

  const navigate = useNavigate();

  const handlePassword = () => {
    getSession().then(({ user }) => {
      if (
        null !== oldPasswordRef.current &&
        null !== newPasswordRef.current &&
        null !== confirmNewPasswordRef.current &&
        newPasswordRef.current.value === confirmNewPasswordRef.current.value
      ) {
        user.changePassword(
          oldPasswordRef.current.value,
          newPasswordRef.current.value,
          (error, result) => {
            if (error) {
              ShowAlert({
                title: "Oooopsss.............!!!",
                text: "Please, double check the information you want to change",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
              });
            } else {
              ShowAlert({
                title: "Password Changed!",
                icon: result.toLowerCase(),
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              }).then(() => {
                navigate("/dashboard");
              });
            }
          }
        );
      } else {
        ShowAlert({
          title: "Oooopsss...!!!",
          text: "Passwords are not the same!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
        });
      }
    });
  };
  return (
    <Form>
      <Row>
        <h3>Credentials</h3>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group controlId="password">
            <Form.Label>Old Password</Form.Label>
            <Form.Control
              ref={oldPasswordRef}
              type="password"
              placeholder="Old Password"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              ref={newPasswordRef}
              type="password"
              placeholder="New Password"
            />
          </Form.Group>
        </Col>
        <Col lg={4}>
          <Form.Group controlId="repeat_password">
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              ref={confirmNewPasswordRef}
              type="password"
              placeholder="Confirm Password"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Button onClick={handlePassword}>Change Password</Button>
      </Row>
    </Form>
  );
};

export default PasswordChange;
