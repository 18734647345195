import { Navbar } from "../components/Navbar";
import { NavProvider } from "../components/providers/NavProvider";

const Dashboard = () => {
  return (
    <NavProvider>
      <Navbar />
    </NavProvider>
  );
};

export default Dashboard;
