const productsFilter = ({ products, nameInput, organization }) => {
  let filteredData = [];

  if (nameInput && nameInput.length > 0) {
    filteredData = products.filter(
      (product) =>
        product.node.title
          .toLowerCase()
          .indexOf(nameInput.toLocaleLowerCase()) !== -1
    );
  } else if (organization && organization.length > 0) {
    products.filter((product) => {
      if (product.org_id === organization) {
        filteredData.push(product);
      }
    });
  } else {
    filteredData = products;
  }

  return filteredData;
};

export default productsFilter;
