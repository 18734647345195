import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ShowroomService from "services/ShowroomService";
import jwt from "jwt-decode";

const StorefrontSelector = ({
  token,
  setSelectedStorefront,
  setOrganizationId,
  isAdmin,
}) => {
  const [storefronts, setStorefronts] = useState([]);

  const onChange = (event) => {
    if (event.target.value !== "Select Storefront") {
      const storefront = JSON.parse(event.target.value);
      setSelectedStorefront(storefront);
      setOrganizationId(storefront.organization_id);
    } else {
      setSelectedStorefront(null);
    }
  };

  useEffect(() => {
    if (token) {
      const config = {
        headers: {
          Authorization: token,
        },
      };
      if (isAdmin) {
        ShowroomService.get("storefronts/admin", config).then((response) => {
          const storefrontsTemp = [];
          const storefronts = response.data;

          for (let index = 0; index < storefronts.length; index++) {
            const element = storefronts[index];

            if (element.metadata.length) {
              const decoded = jwt(element.metadata);
              if (decoded) {
                decoded["type_id"] = element.type_id;
                decoded["id"] = element.id;
                decoded["organization"] = element.organization;
                storefrontsTemp.push(decoded);
              }
            }
          }

          setStorefronts(storefrontsTemp);
        });
      } else {
        ShowroomService.get("storefronts", config).then((response) => {
          const storefrontsTemp = [];
          const storefronts = response.data;

          for (let index = 0; index < storefronts.length; index++) {
            const element = storefronts[index];

            if (element.metadata.length) {
              const decoded = jwt(element.metadata);
              if (decoded) {
                decoded["type_id"] = element.type_id;
                decoded["id"] = element.id;
                decoded["organization"] = element.organization;
                storefrontsTemp.push(decoded);
              }
            }
          }

          setStorefronts(storefrontsTemp);
        });
      }
    }
  }, [token]);

  return (
    <>
      {storefronts?.length > 0 && (
        <Form.Select onChange={onChange} className="search-input">
          <option>Select Storefront</option>
          {storefronts.map((storefront) => {
            return (
              <option
                key={storefront.id}
                value={JSON.stringify({
                  id: storefront.id,
                  domain: storefront.domain,
                  token: storefront.token,
                  organization_id: storefront.organization.alias,
                })}
              >
                {storefront?.organization?.name} - {storefront.domain}
              </option>
            );
          })}
        </Form.Select>
      )}
    </>
  );
};

export default StorefrontSelector;
