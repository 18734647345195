import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, ShowAlert } from "components/globals";
import { DashboardLayout } from "components/layouts";
import { AccountContext } from "components/providers/Account";
import ShowroomService from "services/ShowroomService";
import * as BsIcons from "react-icons/bs";

const accessibilityProps = [
  {
    id: 0,
    name: "Public",
    value: "PB",
  },
  {
    id: 1,
    name: "Private",
    value: "PR",
  },
  {
    id: 2,
    name: "Password",
    value: "PW",
  },
];

const CreateShowroomFormPage = () => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [token, setToken] = useState("");
  const [template, setTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [organizations, setOrganizations] = useState([{ id: "", name: "" }]);
  const [organization, setOrganization] = useState("");
  const [srName, setSrName] = useState("");
  const [accessibility, setAccessibility] = useState(
    accessibilityProps[0].value
  );

  const [passwordAccessibility, setPasswordAccessibility] =
    useState("");
  const [confirmPasswordAccessibility, setConfirmPasswordAccessibility] =
    useState("");

  const { getSession } = useContext(AccountContext);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useLayoutEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);

    getSession().then((data) => {
      if (data.idToken.payload["custom:org_id"] === "mantisxr") {
        setIsAdmin(true);
      } else {
        setOrganization(data.idToken.payload["custom:org_id"]);
      }
      setToken(data.idToken.jwtToken);
    });

    setTemplate(searchParams.get("template"));
  }, []);

  useEffect(() => {
    if (isAdmin) {
      ShowroomService.get("/organizations", {
        headers: { Authorization: token },
      }).then((response) => {
        setOrganizations(response.data);
      });
    }
  }, [isAdmin]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let body = {
      venue: template,
      name: srName,
      accessibility: accessibility,
      // type: "",
      organizationId: organization,
    };

    setButtonLoader(true);

    const config = {
      headers: {
        Authorization: `${token}`,
      },
    };

    if (accessibility === "PW") {
      if (
        passwordAccessibility === confirmPasswordAccessibility &&
        organization !== ""
      ) {
        body = {
          venue: template,
          name: srName,
          accessibility: accessibility,
          pw: passwordAccessibility,
          // type: "DM",
          organizationId: organization,
        };
      } else {
        setButtonLoader(false);
        ShowAlert({
          title: "Passwords don't match or the Organization is empty",
          text: "Please check all the fields",
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      body = {
        name: srName,
        venue: template,
        accessibility: accessibility,
        // type: "DM",
        organizationId: organization,
      };
    }

    ShowroomService.post("/showrooms", body, config)
      .then((response) => {
        if (response.status === 200) {
          ShowAlert({
            title: "Amazing...!!!",
            text: "The data provided had been sent to our Data Base",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            setButtonLoader(false);
            navigate("/dashboard");
          });
        }
      })
      .catch((error) => {
        setButtonLoader(false);
        ShowAlert({
          title: "Ooppss...!!!",
          text: `${error.response.data.message}`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  return (
    <DashboardLayout loader={isLoading}>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <h3 className="mb-3">Create a Showroom</h3>
          </Col>
          <Col lg={1}>
            <button
              onClick={() => navigate(-1)}
              style={{ border: "none", background: "none" }}
            >
              <BsIcons.BsFillArrowLeftSquareFill size={30} />
            </button>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <p className="mb-3">Showroom Information</p>
          </Col>
        </Row>
        {isAdmin && (
          <Row>
            <Col lg={6}>
              <Form.Group className="mb-3" controlId="srOrganization">
                <Form.Label>Organizations</Form.Label>
                <Form.Select
                  required
                  placeholder="Select Organization"
                  onChange={(event) => {
                    setOrganization(event.target.value);
                  }}
                >
                  <option>Organization</option>
                  {organizations.map((organization) => {
                    return (
                      <option value={organization.id} key={organization.id}>
                        {organization.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="srName">
              <Form.Label>Showroom Name</Form.Label>
              <Form.Control
                required
                type="name"
                placeholder="Showroom Name"
                onChange={(event) => {
                  setSrName(event.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="accessibility">
              <Form.Label>Accessibility</Form.Label>
              <Form.Select
                required
                placeholder="Select Accessibility"
                onChange={(event) => {
                  setAccessibility(event.target.value);
                }}
              >
                {accessibilityProps.map((item) => {
                  return (
                    <option value={item.value} key={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        {accessibility === "PW" && (
          <>
            <hr />
            <Row>
              <p>
                You have selected a Password Showroom, please create the
                password to access it
              </p>
            </Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="accessibility">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    required
                    placeholder="Pasword"
                    onChange={(event) => {
                      setPasswordAccessibility(event.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="accessibility">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    required
                    placeholder="Pasword"
                    onChange={(event) => {
                      setConfirmPasswordAccessibility(event.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Button isLoading={buttonLoader} type="submit">
            Submit
          </Button>
        </Row>
      </Form>
    </DashboardLayout>
  );
};

export default CreateShowroomFormPage;
