import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import Service from "./services";
import ShowroomService from "./services/ShowroomService";
import AssetService from "services/AssetService";

import "./styles/global.scss";

const runApp = async () => {
  Service.init();
  ShowroomService.init();
  AssetService.init();

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  );
};

runApp();
