import { DashboardLayout } from "components/layouts";
import {
  ShowAlertIcons,
  useShowroomServiceFetch,
  useShowroomServiceRequest,
} from "hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import jwt from "jwt-decode";
import { Col, Form, Row } from "react-bootstrap";
import { Button } from "components/globals";

const EditStorefrontTokenPage = () => {
  const [domain, setDomain] = useState("");
  const [token, setToken] = useState("");
  const [typeId, setTypeId] = useState(null);

  const { id } = useParams();

  const { sendRequest } = useShowroomServiceRequest();

  const { data, error, loading } = useShowroomServiceFetch(
    `/storefronts/${id}`
  );

  useEffect(() => {
    if (data.metadata) {
      const { metadata } = data;
      const decodedCredentials = jwt(metadata);

      setDomain(decodedCredentials.domain);
      setToken(decodedCredentials.token);
      setTypeId(data.type_id);
    }
  }, [data, error, loading]);

  const onSubmit = (event) => {
    event.preventDefault();

    sendRequest(
      `/storefronts/${id}`,
      "put",
      { data: { type: typeId, metadata: { domain, token } } },
      "Token Updated",
      "Success",
      ShowAlertIcons.SUCCESS,
      "/dashboard/storefront"
    );
  };

  return (
    <DashboardLayout loader={loading}>
      <Row>
        <Col lg={11}>
          <h3>Edit Token of your Online Store Provider</h3>
        </Col>
      </Row>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-3 mt-3">
              <Form.Label>Online Store Domain</Form.Label>
              <Form.Control
                type="text"
                defaultValue={domain}
                placeholder="Domain"
                disabled
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3 mt-3">
              <Form.Label>Online Store Token</Form.Label>
              <Form.Control
                type="text"
                value={token}
                placeholder="Token"
                onChange={(event) => setToken(event.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Button type="submit">Sumbit</Button>
        </Row>
      </Form>
    </DashboardLayout>
  );
};

export default EditStorefrontTokenPage;
