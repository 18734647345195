/* eslint-disable react/no-unknown-property */
import { useEffect, useContext, useState, Suspense } from "react";
import { FaArrowLeft, FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ShowroomService from "services/ShowroomService";
import { AccountContext } from "components/providers/Account";
import { Form, Spinner } from "react-bootstrap";
import { Canvas } from "@react-three/fiber";
import Scene from "components/globals/Scene";
import Shift from "controls/Shift";
import EditProductPreview from "components/globals/EditProductPreview";
import { Hotspot, ShowAlert } from "components/globals";
import ProductCards from "components/CreateProject/ProductCards";
import { ProductPlacementModal } from "components/modals/ProductPlacementModal";
import { EffectComposer, SelectiveBloom } from "@react-three/postprocessing";
import { ProductTooltip } from "./CreateProjectPage/components/ProductTooltip";

const ProjectEditPage = () => {
  const navigate = useNavigate();
  const [onSceneCreated, setOnSceneCreated] = useState(false);
  const [projectData, setProjectData] = useState();
  const [hovered, onHover] = useState(null);
  const selected = hovered ? [hovered] : undefined;
  const [productsInPositions, setProductsInPositions] = useState([]);
  const [placementModalOpen, setPlacementModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState(null);
  const [location, setLocation] = useState(null);
  const [token, setToken] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [editLocation, setEditLocation] = useState();
  const [productsData, setProductsData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const { alias } = useParams();
  const [draggedProduct, setDraggedProduct] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);

  const handleBackButton = () => {
    setOnSceneCreated(false);
    navigate("/dashboard/myShowrooms");
  };

  const shouldRenderProductPositions = () => {
    return productsInPositions?.length > 0 && onSceneCreated;
  };

  const handleOnSceneCreated = () => {
    setOnSceneCreated(true);
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const handleDrop = () => {
    setDraggedProduct(false);
    // setPlacementModalOpen(true);
    // setModalMode("buttons");
  };

  const { getSession } = useContext(AccountContext);

  const addProductToPosition = (location) => {
    setSelectedLocation(location);
    const prodInPositions = productsInPositions.map((product) => {
      if (product.location === location) {
        return {
          ...product,
          product_data: selectedProduct,
        };
      } else {
        return product;
      }
    });
    setProductsInPositions(prodInPositions);
  };

  const handleModalMode = (mode) => {
    setModalMode(mode);
  };

  useEffect(() => {
    getSession().then((data) => {
      setOrgId(data.idToken.payload["custom:org_id"]);
      const token = data.idToken.jwtToken;
      setToken(token);
    });
  }, []);

  useEffect(() => {
    if (token && orgId) {
      const config = {
        headers: {
          Authorization: token,
        },
      };
      ShowroomService.get(`/showrooms/${alias}`, config)
        .then((response) => {
          setProjectData(response.data);
          if (response?.data?.venue?.positions?.length > 0) {
            setProductsInPositions(response.data.venue.positions);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            ShowAlert({
              text: "Unauthorized",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
            navigate("/dashboard");
          }
        });
      ShowroomService.get(`/organizations/${orgId}/models`, {
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          if (res?.data?.length > 0) {
            const products = res.data;
            setProductsData(products);
          } else {
            setProductsData([]);
          }
        })
        .catch();
    }
  }, [token, orgId]);

  useEffect(() => {
    if (projectData?.product_models?.length > 0) {
      let prodInPositions = productsInPositions;
      projectData.product_models.forEach((product_model) => {
        let prods = prodInPositions.map((position) => {
          if (position.location === product_model.location) {
            position.product_data = product_model;
            position.product_data.path = product_model.model.path;
          }
          return position;
        });
        prodInPositions = prods;
      });
      setProductsInPositions(prodInPositions);
    }
  }, [projectData]);

  const handleProductPosition = (location) => {
    addProductToPosition(location);
  };

  const handleSelectedProduct = (option) => {
    setSelectedProduct(option);
  };

  const handleSubmit = () => {
    setButtonLoader(true);
    let modelsData = productsInPositions
      .filter((product) => product.product_data)
      .map((product) => {
        if (product?.product_data?.id) {
          let modelData = {
            location: product.location,
            position: product.position,
            rotation: product.product_data.rotation || product.rotation,
            scale: 1,
          };
          if (product?.product_data?.model) {
            modelData.model_id = product.product_data.model.id;
          } else if (product?.product_data?.id) {
            modelData.model_id = product.product_data.id;
          }
          return modelData;
        }
      });
    modelsData = modelsData.filter((model) => model?.location);
    const config = {
      headers: {
        Authorization: token,
      },
    };
    ShowroomService.post(
      `/showrooms/${alias}/batch-models`,
      { models: modelsData },
      config
    )
      .then((response) => {
        const { message } = response.data;
        if (response.status === 200) {
          setButtonLoader(false);
          ShowAlert({
            title: "Success...!!!",
            text: "Models updated successfully. Would you like to continue editing this project?",
            icon: "success",
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: "No",
          }).then((result) => {
            if (!result.isConfirmed) {
              navigate("/dashboard/myShowrooms");
            }
          });
        }
      })
      .catch((error) => {
        if (error) {
          ShowAlert({
            title: "Ooppss...!!!",
            text: `${error.response.data.message}`,
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        setButtonLoader(false);
      });
  };

  const handleModalPlacement = (modalPlacement) => {
    setPlacementModalOpen(modalPlacement);
  };

  return (
    <div id="create-project-page" className="d-flex">
      <div id="venue-select">
        <div
          className="clickable-text-large lato-regular mx-2 mt-4"
          onClick={handleBackButton}
        >
          <FaArrowLeft className="mx-2" />
          Back to Projects
        </div>
        <div className="w-90% search-bar mx-2 mt-2">
          <FaSearch
            style={{ marginLeft: "1rem", position: "absolute" }}
            color="white"
          />
          <Form.Control
            className="search-input-venues"
            placeholder={"Search"}
          />
        </div>
        <div className="mx-5 mt-2">
          <h5 className="koulen-regular">3d models</h5>
        </div>
        <div id="filtered-venues">
          {productsData.length > 0 && (
            <ProductCards
              products={productsData}
              addProductToPosition={addProductToPosition}
              setSelectedProduct={setSelectedProduct}
              selectedLocation={selectedLocation}
              setDraggedProduct={setDraggedProduct}
            />
          )}
        </div>
        <div className="d-flex align-items-center justify-content-center">
          {buttonLoader ? (
            <button
              disabled
              className="btn btn-dark border border-light"
              onClick={handleSubmit}
            >
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </button>
          ) : (
            <button
              id="project-sumbit-btn"
              className="btn btn-dark border border-light"
              onClick={handleSubmit}
            >
              Submit Changes
            </button>
          )}
        </div>
        {selectedProduct && projectData && (
          <ProductTooltip
            positions={projectData.venue.positions}
            selectedProduct={selectedProduct}
            onClick={handleProductPosition}
            onMouseLeave={handleSelectedProduct}
          />
        )}
      </div>
      <div
        id="venue-preview"
        className="d-flex justify-content-center align-items-center"
      >
        {projectData?.desktop_scene?.length > 0 && (
          <div
            id="venue-canvas"
            onDragOver={(event) => allowDrop(event)}
            onDrop={handleDrop}
          >
            <Canvas>
              <Suspense fallback={null} />
              <ambientLight intensity={2.5} />
              <Scene
                texture={projectData?.desktop_scene}
                onSceneCreated={handleOnSceneCreated}
              />
              <EffectComposer autoClear={false}>
                <SelectiveBloom selection={selected} />
              </EffectComposer>
              <Shift
                selectedProduct={
                  selectedLocation || location
                    ? productsInPositions[
                        parseInt(selectedLocation ?? location) - 1
                      ]
                    : {
                        viewing_position: projectData?.initial_position,
                        viewing_rotation: projectData?.initial_rotation,
                        position: projectData?.initial_position,
                      }
                }
              />
              {productsInPositions?.length > 0 &&
                productsInPositions.map((position) => {
                  if (position?.product_data?.path?.length > 0) {
                    return (
                      <EditProductPreview
                        key={position.product_data.id}
                        onHover={onHover}
                        productLocation={position.location}
                        geometry={position.product_data.path}
                        geometryType="url"
                        textureUrl={null}
                        position={position.position}
                        rotation={position.product_data.rotation}
                        setProductsInPositions={setProductsInPositions}
                        productsInPositions={productsInPositions}
                        location={position.location}
                        setEditLocation={setEditLocation}
                        scale={1}
                      />
                    );
                  } else {
                    return (
                      <Hotspot
                        setPlacementModalOpen={setPlacementModalOpen}
                        key={position.location}
                        setModalMode={setModalMode}
                        position={position}
                        onHover={onHover}
                        setLocation={setLocation}
                        addProductToPosition={addProductToPosition}
                        draggedProduct={draggedProduct}
                        setDraggedProduct={setDraggedProduct}
                        onModalMode={handleModalMode}
                        onPlacementModal={handleModalPlacement}
                      />
                    );
                  }
                })}
            </Canvas>
          </div>
        )}
      </div>
      <ProductPlacementModal
        setPlacementModalOpen={setPlacementModalOpen}
        placementModalOpen={placementModalOpen}
        selectedProduct={selectedProduct}
        addProductToPosition={addProductToPosition}
        location={location}
        modalMode={modalMode}
        productsInPositions={productsInPositions}
      />
    </div>
  );
};

export default ProjectEditPage;
