import { FaArrowLeft } from "react-icons/fa";

export const GoBack = ({ onClick, currentlyViewed }) => {
  return (
    <div className="lato-regular mx-2 mt-4" onClick={onClick}>
      <FaArrowLeft className="mx-2" />
      {currentlyViewed ? "Select Venue" : "Back to Projects"}
    </div>
  );
};
