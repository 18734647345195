export const ProductTooltip = ({
  positions,
  selectedProduct,
  onClick,
  onMouseLeave,
}) => {
  const handleOnClick = (position) => {
    onClick(position.location);
  };

  const handleMouseLeave = () => {
    onMouseLeave(false);
  };

  return (
    <div
      className="tooltip-detail tooltip-venue"
      style={{ zIndex: 20, position: "absolute", left: "4%", top: "74%" }}
    >
      <div className="w-100 h-100" onMouseLeave={handleMouseLeave}>
        <h5 className="koulen-regular mt-2 ms-2 text-dark">
          {selectedProduct.name.length > 50
            ? `${selectedProduct.name.substr(0, 47)}...`
            : selectedProduct.name}
        </h5>
        <p className="lato-regular body-text ms-2 mt-1">Add to location</p>
        <div id="tag-container" className="d-flex flex-wrap">
          {positions?.map((position) => {
            return (
              <div
                className="modal-tag lato-regular btn btn-outline-dark ms-1 px-2"
                onClick={() => handleOnClick(position)}
                key={position.location}
              >
                {position.location}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
