import { Card, Col, Container, Row } from "react-bootstrap";
import { Loader } from "../globals";

const DashboardLayout = ({ children, title, loader = false }) => {
  return (
    <>
      {!loader ? (
        <main className="d-flex w-100">
          <Container className="d-flex flex-column">
            {title !== undefined ? (
              <Row>
                <Col sm={10} md={10} lg={10} className="mx-auto mt-5">
                  <div className="text-start mt-4">
                    <h1 className="h2">{title}</h1>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row className="">
              <Col sm={10} md={10} lg={10} className="mx-auto mt-5">
                <div className="align-middle">
                  <Card className="shadow border-0">
                    <Card.Body>
                      <div className="">{children}</div>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </main>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default DashboardLayout;
