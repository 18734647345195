export const positions = [
  {
    id: [6.7, 1, -1],
    name: "1",
  },
  {
    id: [6.7, 1, -6.5],
    name: "2",
  },
  {
    id: [6.7, 1, -11],
    name: "3",
  },
  {
    id: [-21.5, 1, -2],
    name: "4",
  },
  {
    id: [-20, 1, -12.2],
    name: "5",
  },
  {
    id: [-21.5, 1, -11],
    name: "6",
  },
  {
    id: [-21.5, 1, -11],
    name: "7",
  },
  {
    id: [-15, -1, -12.2],
    name: "8",
  },
  {
    id: [-10, 1, -12.2],
    name: "9",
  },
  {
    id: [-5, 1, -12],
    name: "10",
  },
  {
    id: [0, 1, -12.2],
    name: "11",
  },
  {
    id: [5, 1, -12.2],
    name: "12",
  },
];
