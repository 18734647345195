import { useContext, useRef } from "react";
import { Form } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, ShowAlert } from "../components/globals";
import { RegistrationLayout } from "../components/layouts";
import { AccountContext } from "../components/providers/Account";

const PasswordResetPage = () => {
  const emailRef = useRef(null);

  const navigate = useNavigate();

  const { passwordReset } = useContext(AccountContext);

  const onSubmit = (event) => {
    event.preventDefault();
    if (null !== emailRef.current) {
      passwordReset(emailRef.current.value)
        .then((data) => {
          if (data) {
            ShowAlert({
              title: "A Code has been sent to your email!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              navigate("/password-recovery");
            });
          }
        })
        .catch((error) => {
          if (error) {
            ShowAlert({
              title: "Oooopsss.............!!!",
              text: `${error.message}`,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        });
    }
  };

  return (
    <RegistrationLayout title="Password Reset">
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Enter the email below to reset your password</Form.Label>
          <Form.Control
            required
            ref={emailRef}
            type="email"
            placeholder="Email"
          />
        </Form.Group>
        <Button type="submit">Recover Password</Button>
        <div className="text-center mt-3">
          <p>Or</p>
        </div>
        <div className="text-center">
          <NavLink className="link-dark" to="/login">
            Log In
          </NavLink>
        </div>
      </Form>
    </RegistrationLayout>
  );
};

export default PasswordResetPage;
