import { useState, useContext, useRef, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Button, ShowAlert } from "components/globals";
import * as AiIcons from "react-icons/ai";
import RegistrationLayout from "../components/layouts/RegistrationLayout";
import { AccountContext } from "components/providers/Account";
import { useNavigate } from "react-router-dom";

const RegisterPage = () => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [passwordView, setPasswordView] = useState(false);
  const [confirmPasswordView, setConfirmPasswordView] = useState(false);
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);

  const { register, getSession } = useContext(AccountContext);

  useEffect(() => {
    getSession()
      .then((data) => {
        setToken(data.idToken.jwtToken);
      })
      .catch((error) => {
        error;
      });
  }, []);

  useEffect(() => {
    if (token) {
      window.parent.postMessage(
        JSON.stringify({
          type: "Authenticated",
          token,
        }),
        "*"
      );
    }
  }, [token]);

  const onSubmit = (event) => {
    event.preventDefault();
    setButtonLoader(true);
    if (confirmPasswordRef?.current?.value !== passwordRef?.current?.value) {
      ShowAlert({
        title: "Oooopsss.............!!!",
        text: "Please ensure your passwords match",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
      setButtonLoader(false);
    } else {
      if (
        null !== emailRef.current &&
        null !== passwordRef.current &&
        null !== firstNameRef.current &&
        null !== lastNameRef.current &&
        null !== confirmPasswordRef.current
      ) {
        const email = emailRef.current.value;
        register(
          email,
          passwordRef.current.value,
          firstNameRef.current.value,
          lastNameRef.current.value
        )
          .then((data) => {
            if (data) {
              ShowAlert({
                title: "You have successfully registered for MantisXR!",
                text: "An email with a verification code has been sent to the provided email.",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              }).then(() => {
                setButtonLoader(false);
                navigate(`/email-verification?username=${email}`);
              });
            }
          })
          .catch((err) => {
            if (err) {
              ShowAlert({
                title: "Oooopsss.............!!!",
                text: `${err.message}`,
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
              });
              setButtonLoader(false);
            }
          });
      }
    }
  };

  const handlePasswordView = (event) => {
    event.preventDefault();
    setPasswordView(!passwordView);
  };

  const handleConfirmPasswordView = (event) => {
    event.preventDefault();
    setConfirmPasswordView(!confirmPasswordView);
  };

  const handleSignOut = () => {
    setButtonLoader(false);
    setToken("");
  };

  return (
    <RegistrationLayout title="Welcome To">
      {!token ? (
        <Form onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              ref={emailRef}
              required
              type="email"
              placeholder="Email"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              ref={firstNameRef}
              required
              type="text"
              placeholder="First Name"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              ref={lastNameRef}
              required
              type="text"
              placeholder="Last Name"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password</Form.Label>
            <Row>
              <Col lg={11}>
                <Form.Control
                  ref={passwordRef}
                  required
                  type={passwordView ? "text" : "password"}
                  placeholder="Password"
                />
              </Col>
              <Col lg={1}>
                <button
                  onClick={handlePasswordView}
                  style={{ border: "none", background: "none" }}
                >
                  {passwordView ? (
                    <AiIcons.AiOutlineEyeInvisible />
                  ) : (
                    <AiIcons.AiOutlineEye />
                  )}
                </button>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Row>
              <Col lg={11}>
                <Form.Control
                  ref={confirmPasswordRef}
                  required
                  type={confirmPasswordView ? "text" : "password"}
                  placeholder="Password"
                />
              </Col>
              <Col lg={1}>
                <button
                  onClick={handleConfirmPasswordView}
                  style={{ border: "none", background: "none" }}
                >
                  {confirmPasswordView ? (
                    <AiIcons.AiOutlineEyeInvisible />
                  ) : (
                    <AiIcons.AiOutlineEye />
                  )}
                </button>
              </Col>
            </Row>
          </Form.Group>
          <div className="d-flex justify-content-between">
            <NavLink className="link-dark" to="/login">
              Already have an account?
            </NavLink>
          </div>
          <Button isLoading={buttonLoader} type="submit">
            Sign Up
          </Button>
        </Form>
      ) : (
        <div>
          <Button
            isLoading={buttonLoader}
            onClick={handleSignOut}
            type="submit"
          >
            Sign Out
          </Button>
        </div>
      )}
    </RegistrationLayout>
  );
};

export default RegisterPage;
