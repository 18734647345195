import AddGeometryPage from "pages/AddGeometryPage";
import { useContext, useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AccountContext } from "../components/providers/Account";
import {
  AccountPage,
  CreateShowroomFormPage,
  Dashboard,
  LoginPage,
  PasswordChangePage,
  PasswordResetPage,
  SignupPage,
  MyShowroomsPage,
  CreateOrganizationPage,
  CreateProductsPage,
  HomePage,
  AddProductsToShowroomPage,
  StorefrontFormPage,
  EditStorefrontTokenPage,
  AddModelPage,
  ModelDetailsPage,
  CreateProjectPage,
  VerificationPage,
  ProjectEditPage,
  LogosPage,
  RegisterPage,
} from "../pages";
import ProductDetails from "../pages/ProductDetailsPage";
import PrivateRoute from "./PrivateRoute";

const AppRouter = () => {
  const { getSession } = useContext(AccountContext);

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    getSession()
      .then((data) => {
        if (data) {
          if (location.pathname === "/login") {
            navigate("/dashboard");
          }
        } else {
          navigate("/login");
        }
      })
      .catch((error) => {
        error;
      });
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<RegisterPage />} />
      <Route path="/password-reset" element={<PasswordResetPage />} />
      <Route path="/password-recovery" element={<PasswordChangePage />} />
      <Route path="email-verification" element={<VerificationPage />} />
      <Route path="signup/:token" element={<SignupPage />} />
      <Route element={<PrivateRoute />}>
        <Route
          path="dashboard"
          element={
            <>
              <Dashboard />
              <Outlet />
            </>
          }
        >
          <Route path="" element={<HomePage />} />
          <Route path="account" element={<AccountPage />} />
          <Route path="projects/create" element={<CreateProjectPage />} />
          <Route path="projects/:alias/edit" element={<ProjectEditPage />} />
          <Route path="create-showroom" element={<CreateShowroomFormPage />} />
          <Route path="myShowrooms" element={<MyShowroomsPage />} />
          <Route
            path="createOrganization"
            element={<CreateOrganizationPage />}
          />
          <Route path="logos" element={<LogosPage />} />
          <Route path="products" element={<ProductDetails />} />
          <Route path="models" element={<ModelDetailsPage />} />
          <Route path="upload-products" element={<CreateProductsPage />} />
          <Route path="select-geometry" element={<AddGeometryPage />} />
          <Route
            path="products/:serial_number/add-model"
            element={<AddModelPage />}
          />
          <Route path="storefront" element={<StorefrontFormPage />} />
          <Route
            path="add-products-to-showroom"
            element={<AddProductsToShowroomPage />}
          />
          <Route
            path="edit-storefront/:id"
            element={<EditStorefrontTokenPage />}
          />
        </Route>
      </Route>
      <Route path="/" element={<Navigate to="login" />} />
    </Routes>
  );
};

export default AppRouter;
