import { useContext, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, ShowAlert } from "../components/globals";
import { RegistrationLayout } from "../components/layouts";
import { AccountContext } from "../components/providers/Account";

const PasswordChangePage = () => {
  const [recovery, setRecovery] = useState(false);

  const emailRef = useRef(null);
  const codeRef = useRef(null);
  const passwordRef = useRef(null);
  const repeatPasswordRef = useRef(null);
  const oldPasswordRef = useRef(null);

  const { passwordChangeWithCode, getSession } = useContext(AccountContext);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/password-recovery") {
      setRecovery(true);
    }
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    if (
      recovery &&
      emailRef.current !== null &&
      passwordRef.current !== null &&
      repeatPasswordRef.current !== null
    ) {
      if (
        passwordRef.current.value === repeatPasswordRef.current.value &&
        codeRef.current !== null
      ) {
        passwordChangeWithCode(
          emailRef.current.value,
          codeRef.current.value,
          passwordRef.current.value
        )
          .then((data) => {
            if (data === "SUCCESS") {
              ShowAlert({
                title: "Password changed successfully!",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                navigate("/login");
              });
            }
          })
          .catch((error) => {
            if (error) {
              ShowAlert({
                title: "Oooopsss.............!!!",
                text: `${error.message}`,
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          });
      } else {
        ShowAlert({
          title: "Passwords are not the same",
          text: "Make sure you have the same password in both fields",
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } else {
      if (
        null !== oldPasswordRef.current &&
        null !== passwordRef.current &&
        null !== repeatPasswordRef.current &&
        passwordRef.current.value === repeatPasswordRef.current.value
      ) {
        getSession().then(({ user }) => {
          if (null !== oldPasswordRef.current && passwordRef.current !== null) {
            user.changePassword(
              oldPasswordRef.current.value,
              passwordRef.current.value,
              (err, result) => {
                if (err) {
                  ShowAlert({
                    title: "Oooopsss.............!!!",
                    text: `${err.message}`,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2000,
                  });
                } else {
                  const icon = result.toLowerCase();

                  ShowAlert({
                    title: `Password changed ${icon}fully!`,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                  }).then(() => {
                    navigate("/dashboard");
                  });
                }
              }
            );
          }
        });
      } else {
        ShowAlert({
          title: "Password are not the same",
          text: "Make sure you have the same password in both fields",
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  return (
    <RegistrationLayout title="Reset Password">
      <Form onSubmit={onSubmit}>
        {recovery ? (
          <>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                ref={emailRef}
                type="text"
                placeholder="Email"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Verification Code</Form.Label>
              <Form.Control
                required
                ref={codeRef}
                type="number"
                placeholder="Enter the code sent to your email"
              />
            </Form.Group>
          </>
        ) : (
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Old Password</Form.Label>
            <Form.Control
              required
              ref={oldPasswordRef}
              type="password"
              placeholder="Enter your old password"
            />
          </Form.Group>
        )}
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            ref={passwordRef}
            type="password"
            placeholder="Password"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="repeatPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            required
            ref={repeatPasswordRef}
            type="password"
            placeholder="Password"
          />
        </Form.Group>
        <Button type="submit">Change Password</Button>
      </Form>
    </RegistrationLayout>
  );
};

export default PasswordChangePage;
