const Pagination = ({
  optionsPerPage,
  totalOptions,
  paginate,
  currentPage,
}) => {
  const pageNumbers = [];

  for (
    let index = 1;
    index <= Math.ceil(totalOptions / optionsPerPage);
    index++
  ) {
    pageNumbers.push(index);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => {
          const isSelected = number === currentPage;

          return (
            <li key={number} className="page-item">
              <button
                onClick={() => paginate(number)}
                className={`page-link__custom ${
                  isSelected ? "page-link__custom--active" : ""
                }`}
              >
                {number}
              </button>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Pagination;
