/* eslint-disable no-undef */
import axios from "axios";

class AssetService {
  static axiosInstance;

  static init() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BE_API_ENDPOINT,
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    });
  }

  static async get(url, config) {
    return await AssetService.axiosInstance.get(url, config);
  }

  static async post(url, data, config) {
    return await AssetService.axiosInstance.post(url, data, config);
  }

  static async put(url, data, config) {
    return await AssetService.axiosInstance.put(url, data, config);
  }

  static async delete(url, config) {
    return await AssetService.axiosInstance.delete(url, config);
  }
}

export default AssetService;
