/* eslint-disable react/no-unknown-property */
import { useState, useEffect, useMemo, useRef } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { TextureLoader, MeshStandardMaterial } from "three";
import { useLoader } from "@react-three/fiber";
import { CanvasTooltip } from "./CanvasTooltip";
import { FaArrowsRotate, FaX } from "react-icons/fa6";
import { Html } from "@react-three/drei";

const data = {
  rotation: [0, 0, 0],
  scale: 1,
};

const EditProductPreview = ({
  textureUrl,
  geometry,
  geometryType,
  position,
  rotation,
  scale,
  onHover,
  location,
  productsInPositions,
  setProductsInPositions,
}) => {
  let texture = null;
  if (textureUrl) {
    texture = useLoader(TextureLoader, textureUrl);
    texture.flipY = false;
  }
  const [hovered, setHovered] = useState(false);
  const [modelData, setModelData] = useState(data);
  const [model, setModel] = useState(null);
  const [editModelOpen, setEditModalOpen] = useState(false);
  const [productRotation, setProductRotation] = useState(null);
  const [productScale, setProductScale] = useState(null);
  const ref = useRef();

  const handlePointerOver = () => {
    setHovered(true);
    onHover(ref);
  };

  const handlePointerOut = () => {
    setHovered(false);
    onHover(null);
  };

  const handleClick = () => {
    setHovered(false);
    setEditModalOpen(true);
  };

  const handleRotate = () => {
    const products = productsInPositions;
    const product = products[parseInt(location) - 1];
    if (product?.product_data?.rotation?.length > 0) {
      products[parseInt(location) - 1].product_data.rotation = [
        product.product_data.rotation[0],
        product.product_data.rotation[1] + 0.78,
        product.product_data.rotation[2],
      ];
      setProductRotation([
        product.product_data.rotation[0],
        product.product_data.rotation[1] + 0.78,
        product.product_data.rotation[2],
      ]);
    }
    if (!product?.product_data?.rotation && product?.rotation?.length > 0) {
      products[parseInt(location) - 1].rotation = [
        product.rotation[0],
        product.rotation[1] + 0.78,
        product.rotation[2],
      ];
      setProductRotation([
        product.rotation[0],
        product.rotation[1] + 0.78,
        product.rotation[2],
      ]);
    }

    setProductsInPositions([...products]);
  };

  const handleClose = () => {
    productsInPositions[parseInt(location) - 1].product_data = {};
    setProductsInPositions([...productsInPositions]);
  };

  const meshMaterial = useMemo(() => {
    let meshMaterial = null;
    if (texture) {
      meshMaterial = new MeshStandardMaterial({
        map: texture,
        side: 2,
      });
    }
    return meshMaterial;
  }, [texture]);

  useEffect(() => {
    const loader = new GLTFLoader();
    let isMounted = true;
    if (geometryType === "url") {
      loader.load(geometry, (gltf) => {
        if (meshMaterial && isMounted) {
          gltf.scene.children[0].material = meshMaterial;
        }
        setModel(gltf.scene);
      });
    } else if (geometryType === "file") {
      loader.load(URL.createObjectURL(geometry), (gltf) => {
        if (meshMaterial && isMounted) {
          gltf.scene.children[0].material = meshMaterial;
        }
        setModel(gltf.scene);
      });
    }

    return () => {
      isMounted = false;
      setModel(null);
    };
  }, [geometry, meshMaterial, geometryType]);

  return (
    <>
      {meshMaterial && model && (
        <mesh
          ref={ref}
          position={position}
          rotation={productRotation || rotation}
          scale={productScale || scale}
          material={meshMaterial}
          material-envMapIntensity={hovered ? 10 : 2}
          onPointerOver={handlePointerOver}
          onPointerOut={handlePointerOut}
          onClick={handleClick}
        >
          <CanvasTooltip
            hover={hovered}
            message={"Click to rotate"}
            position={position}
            rotation={rotation}
          />
          <primitive object={model} />
        </mesh>
      )}
      {!meshMaterial && model && (
        <mesh
          ref={ref}
          position={position}
          rotation={productRotation || rotation}
          scale={productScale || scale}
          material-envMapIntensity={hovered ? 10 : 2}
          onPointerOver={handlePointerOver}
          onPointerOut={handlePointerOut}
          onClick={handleClick}
        >
          <primitive object={model} />
          <CanvasTooltip
            hover={hovered}
            message={"Click to rotate"}
            position={position}
            rotation={rotation}
          />
        </mesh>
      )}
      {editModelOpen && (
        <>
          <Html
            position={[position[0] - 0.2, position[1], position[2]]}
            rotation={rotation}
            scale={0.5}
            whiteSpace="normal"
            color="black"
          >
            <div className="d-flex align-items-center justify-content-center rotate-circle">
              <FaArrowsRotate size={30} color="white" onClick={handleRotate} />
            </div>
          </Html>
          <Html
            position={[position[0] - 0.2, position[1] + 2, position[2]]}
            rotation={rotation}
            scale={0.5}
            whiteSpace="normal"
            color="black"
          >
            <div className="d-flex align-items-center justify-content-center rotate-circle">
              <FaX size={30} color="white" onClick={handleClose} />
            </div>
          </Html>
        </>
      )}
    </>
  );
};

export default EditProductPreview;
