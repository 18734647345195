const showroomFilter = ({ showrooms, nameInput }) => {
  let filteredData;

  if (nameInput?.length > 0) {
    filteredData = showrooms.filter(
      (showroom) =>
        showroom.name
          .toLocaleLowerCase()
          .indexOf(nameInput.toLocaleLowerCase()) !== -1
    );
  } else {
    filteredData = showrooms;
  }

  return filteredData;
};

export default showroomFilter;
