import { useEffect, useMemo, useState } from "react";
import pako from "pako";
import { useGLTF } from "@react-three/drei";

const collisionFloor = new RegExp("^collision_floor");

const Scene = ({ texture, onSceneCreated }) => {
  const [model, setModel] = useState(null);

  useEffect(() => {
    const loadModel = async () => {
      const response = await fetch(texture);

      const data = response.clone();

      const buffer = await data.arrayBuffer();
      const inflated = pako.inflate(buffer);
      const blob = new Blob([inflated]);
      const url = URL.createObjectURL(blob);
      setModel(url);
    };

    loadModel();
  }, []);

  const scene = useMemo(() => {
    if (model) {
      const { scene } = useGLTF(model);
      scene.traverse((obj) => {
        if (obj.isMesh) {
          if (collisionFloor.test(obj.name)) {
            // set collider here
          }
        }
      });

      onSceneCreated();
      return scene;
    }
  }, [model]);

  return (
    <group dispose={null} is="model-group">
      {!!scene && <primitive object={scene} is="scene" />}
    </group>
  );
};

export default Scene;
