import { ShowAlert } from "components/globals";
import { AccountContext } from "components/providers/Account";
import { useContext, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShowroomService from "services/ShowroomService";

export const ShowAlertIcons = {
  SUCCESS: "success",
  ERROR: "error",
};

const useShowroomServiceRequest = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);

  const navigate = useNavigate();

  const { getSession } = useContext(AccountContext);

  useLayoutEffect(() => {
    setLoading(true);

    getSession().then((response) => {
      setToken(response.idToken.jwtToken);
    });
  }, []);

  const sendRequest = (
    url,
    method,
    body,
    successMessage,
    successTitle,
    icon,
    resolveUrl
  ) => {
    setError(null);
    setLoading(true);

    if (token) {
      const config = {
        headers: {
          Authorization: token,
        },
      };

      if (method === "post") {
        ShowroomService.post(url, body, config)
          .then((response) => {
            setData(response.data);

            if (response.status === 200) {
              ShowAlert({
                title: successTitle,
                text: successMessage,
                icon: icon,
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                navigate(resolveUrl);
              });
            }
          })
          .catch((error) => {
            setError(error.response.data.message);
            ShowAlert({
              title: "Ooopsss...!!!",
              text: error.response.data.message,
              icon: ShowAlertIcons.ERROR,
              showConfirmButton: false,
              timer: 2000,
            });
          });
      } else if (method === "put") {
        ShowroomService.put(url, body, config)
          .then((response) => {
            setData(response.data);

            if (response.status === 200) {
              ShowAlert({
                title: successTitle,
                text: successMessage,
                icon: icon,
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                navigate(resolveUrl);
              });
            }
          })
          .catch((error) => {
            setError(error.response.data.message);
            ShowAlert({
              title: "Ooopsss...!!!",
              text: error.response.data.message,
              icon: ShowAlertIcons.ERROR,
              showConfirmButton: false,
              timer: 2000,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };
  return { data, error, loading, sendRequest };
};
export default useShowroomServiceRequest;
