import { AccountContext } from "components/providers/Account";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import ShowroomService from "services/ShowroomService";

const useShowroomServiceFetch = (url) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [token, setToken] = useState(null);

  const { getSession } = useContext(AccountContext);

  useLayoutEffect(() => {
    setLoading(true);

    getSession()
      .then((response) => {
        setToken(response.idToken.jwtToken);
      })
      .catch();
  }, []);

  const fetchData = async () => {
    if (token) {
      const config = {
        headers: {
          Authorization: token,
        },
      };

      ShowroomService.get(url, config)
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          setError(error.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [url, token]);

  const refetch = () => {
    fetchData();
  };

  return { data, error, loading, refetch };
};

export default useShowroomServiceFetch;
