/* eslint-disable no-undef */
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { Loader, Pagination, ShowAlert } from "components/globals";
import { AccountContext } from "components/providers/Account";
import { showroomFilter } from "helpers";
import { useNavigate } from "react-router-dom";
import { ShowroomModal } from "components/modals";
import { useShowroomServiceFetch } from "hooks";
import {
  FaSearch,
  FaPen,
  FaEllipsisH,
  FaExternalLinkAlt,
} from "react-icons/fa";

const MyShowroomsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [optionsPerPage] = useState(8);
  const [token, setToken] = useState();
  const [currentOptions, setCurrentOptions] = useState([]);
  const [ownerOrgId, setOwnerOrgId] = useState();
  const [filterInput, setFilterInput] = useState();
  const [filteredShowrooms, setFilteredShowrooms] = useState([]);

  const [projectSelected, setProjectSelected] = useState({});
  const [projectDetailModal, setProjectDetailModal] = useState(false);

  const navigate = useNavigate();

  const filterInputRef = useRef(null);

  const { getSession } = useContext(AccountContext);

  const { data, error, loading } = useShowroomServiceFetch(
    "/organizations/showrooms"
  );

  const handleProjectEdit = (id) => {
    navigate(`/dashboard/projects/${id}/edit`);
  };

  const handleProjectView = (id) => {
    window.open(
      `${process.env.REACT_APP_METAVERSE_URL}/dynamic-showrooms/${id}`,
      "_blank"
    );
  };

  const modalHandler = (handler) => {
    setProjectDetailModal(handler);
  };

  const projectDetailModalHandlerShow = (project, trigger) => {
    setProjectSelected({ project, trigger });
    setProjectDetailModal(true);
  };

  useLayoutEffect(() => {
    getSession().then((response) => {
      setToken(response.idToken.jwtToken);
      setOwnerOrgId(response.idToken.payload["custom:org_id"]);
    });
  }, []);

  const renderPopover = (props) => {
    return (
      <>
        <Popover className="floating-menu-logo" {...props}>
          <h5 className="koulen-regular mx-2 mt-2">{projectSelected?.name}</h5>
          <hr />
          <div className="d-flex flex-column justify-content-evenly">
            {projectSelected.template && (
              <div
                className="clickable-action mx-2 lato-regular-16 d-flex align-items-center"
                onClick={() => handleProjectEdit(projectSelected?.alias)}
              >
                <FaPen color="hsla(0, 0%, 17%, 1)" size={16} className="me-1" />
                Edit Project
              </div>
            )}
            <div
              className="clickable-action mx-2 lato-regular-16 d-flex align-items-center mt-3"
              onClick={() => handleProjectView(projectSelected?.alias)}
            >
              <FaExternalLinkAlt size={16} className="me-1" />
              Open in a new tab
            </div>
          </div>
        </Popover>
      </>
    );
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginationControl = (event) => {
    event.preventDefault();

    const button = event.currentTarget;

    if (
      button.name === "next" &&
      currentPage < Math.ceil(filteredShowrooms?.length / optionsPerPage)
    ) {
      setCurrentPage(currentPage + 1);
    }

    if (button.name === "previous" && currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    if (!loading) {
      setCurrentOptions(data);
      setFilteredShowrooms(data);
    }
  }, [loading]);

  useEffect(() => {
    if (filteredShowrooms?.length > 0) {
      const indexOfLastOption = currentPage * optionsPerPage;
      const indexOfFirstOption = indexOfLastOption - optionsPerPage;
      setCurrentOptions(
        filteredShowrooms.slice(indexOfFirstOption, indexOfLastOption)
      );
    } else {
      setCurrentOptions(data);
    }

    if (error?.length > 0) {
      ShowAlert({
        title: "Ooppss...!!",
        text: error,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [data, error, loading, filteredShowrooms, currentPage]);

  useEffect(() => {
    const filteredDataByName = showroomFilter({
      showrooms: data,
      nameInput: filterInput,
    });
    setFilteredShowrooms(filteredDataByName);
  }, [filterInput]);

  const showroomsFromModal = (showrooms) => {
    setFilteredShowrooms(showrooms);
  };

  return (
    <>
      <div className="main-heading">
        <div>
          <h2 className="mt-3">Projects</h2>
        </div>
        <div
          className="btn btn-outline-light lato-regular mt-3"
          onClick={() => navigate("/dashboard/projects/create")}
        >
          + New Project
        </div>
      </div>
      <div className="main-heading mt-3">
        <div className="w-50% search-bar">
          <FaSearch
            style={{ marginLeft: "1rem", position: "absolute" }}
            color="white"
          />
          <Form.Control
            className="search-input"
            ref={filterInputRef}
            onChange={(event) => {
              setFilterInput(event.target.value);
            }}
            placeholder={"Search"}
          />
        </div>
      </div>
      <div id="projects-preview" className="koulen-regular mt-3">
        <h4>Your Projects</h4>
        <div id="view-project-cards">
          {data?.length > 0 ? (
            <>
              {currentOptions?.map((room) => {
                return (
                  <>
                    <div className="card-container">
                      <div
                        className="project-card view-project-card m-3"
                        key={room.alias}
                        style={{
                          backgroundImage: `url("${
                            room?.preview_image ||
                            "https://3dmodelsbucket.s3.us-west-2.amazonaws.com/projects/mariners-suite/marinersPressClub.png"
                          }")`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      >
                        <>
                          <OverlayTrigger
                            placement="bottom"
                            trigger="click"
                            onHide={() => this.setState({ show: false })}
                            rootClose={true}
                            rootCloseEvent="mousedown"
                            overlay={renderPopover}
                          >
                            <div className="ellpisis-icon-img logo">
                              <FaEllipsisH
                                color="white"
                                onClick={() => setProjectSelected(room)}
                              />
                            </div>
                          </OverlayTrigger>
                        </>
                      </div>
                      <p className="lato-regular">{room.name}</p>
                    </div>
                  </>
                );
              })}
            </>
          ) : (
            <>
              {loading ? (
                <Loader />
              ) : (
                <div id="no-projects">
                  <h2>You currently do not have any projects</h2>
                  <div
                    className="btn btn-outline-light lato-regular mt-3"
                    onClick={() => navigate("/dashboard/projects/create")}
                  >
                    {" "}
                    + New Project
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {filteredShowrooms?.length > 1 && (
        <div className="main-heading main-pagination-container">
          <div className="pagination-container">
            <Pagination
              optionsPerPage={optionsPerPage}
              totalOptions={filteredShowrooms.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      )}
      <ShowroomModal
        modalHandler={modalHandler}
        showroomModalHandler={projectDetailModal}
        showroomSelected={projectSelected}
        token={token}
        refreshShowrooms={showroomsFromModal}
      />
    </>
  );
};

export default MyShowroomsPage;