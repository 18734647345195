import { Table } from "react-bootstrap";
import * as FaIcons from "react-icons/fa";
import * as FiIcons from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const StorefrontTable = ({ storefrontArray, setStorefrontId }) => {
  const navigate = useNavigate();

  const provider = (provider) => {
    switch (provider) {
      case 1:
        return "Shopify";
      case 2:
        return "SAPHybris";
    }
  };

  const handleStorefrontEdit = (id) => {
    navigate(`/dashboard/edit-storefront/${id}`);
  };

  return (
    <>
      {storefrontArray.length ? (
        <div className="overflow-auto sf__table_container mt-3">
          <Table striped bordered>
            <thead>
              <tr>
                <th>Domain</th>
                <th>Token</th>
                <th>Type</th>
                <th>Delete</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {storefrontArray.map((item) => {
                return (
                  <tr key={item.id}>
                    <td className="align-middle text-center">{item.domain}</td>
                    <td className="align-middle text-center">{item.token}</td>
                    <td className="align-middle text-center">
                      {provider(item.type_id)}
                    </td>
                    <td className="align-middle text-center">
                      <button
                        style={{ border: "none", background: "none" }}
                        onClick={() => setStorefrontId(item.id)}
                      >
                        <FaIcons.FaRegTrashAlt size={25} />
                      </button>
                    </td>
                    <td className="align-middle text-center">
                      <button
                        style={{ border: "none", background: "none" }}
                        onClick={() => handleStorefrontEdit(item.id)}
                      >
                        <FiIcons.FiEdit size={25} />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : null}
    </>
  );
};

export default StorefrontTable;
