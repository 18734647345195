import Client from "shopify-buy/index.unoptimized.umd";
import { ShowAlert } from "../components/globals";

const shopifyAllProducts = async ({ shopDom, shopTok }) => {
  const products = [];
  try {
    const client = Client.buildClient({
      domain: shopDom,
      storefrontAccessToken: shopTok,
    });

    const productsQuery = (offset) => {
      return client.graphQLClient.query((root) => {
        root.addConnection(
          "products",
          offset
            ? { args: { first: 250, after: offset } }
            : { args: { first: 250 } },
          (products) => {
            products.add("id");
            products.add("vendor");
            products.add("description");
            products.add("productType");
            products.add("onlineStoreUrl");
            products.add("createdAt");
            products.add("handle");
            products.add("title");
            products.add("availableForSale");
            products.add("images", { args: { first: 6 } }, function (images) {
              images.add("pageInfo", function (pageInfo) {
                pageInfo.add("hasNextPage");
                pageInfo.add("hasPreviousPage");
              });
              images.add("edges", function (edges) {
                edges.add("cursor");
                edges.add("node", function (node) {
                  node.add("id");
                  node.add("url", {
                    alias: "src",
                  });
                  node.add("altText");
                  node.add("width");
                  node.add("height");
                });
              });
            });
          }
        );
      });
    };

    const makeProductRequest = async (cursor, hasNextPage) => {
      if (hasNextPage) {
        const { lastCursor, hasNextPage } = await client.graphQLClient
          .send(productsQuery(cursor))
          .then((data) => {
            const lastIndex = data.data.products.edges.length - 1;
            const lastCursor = data.data.products.edges[lastIndex].cursor;
            const hasNextPage = data.data.products.pageInfo.hasNextPage;
            products.push(...data.data.products.edges);
            return { lastCursor, hasNextPage };
          });
        return makeProductRequest(lastCursor, hasNextPage);
      }
    };
    await makeProductRequest("", true);
    return products;
  } catch (error) {
    ShowAlert({
      title: "Oooopsss.............!!!",
      text: "Something went wrong when fetching your products",
      icon: "error",
      showConfirmButton: false,
      timer: 2000,
    });
  }
};

export default shopifyAllProducts;
