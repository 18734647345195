import { Account } from "./components/providers/Account";
import { OrganisationData } from "components/providers/OrganisationDataProvider";
import { GeometryData } from "components/providers/GeometryDataProvider";
import AppRouter from "./router/AppRouter";

import "./app.css";

const App = () => {
  return (
    <Account>
      <OrganisationData>
        <GeometryData>
          <AppRouter />
        </GeometryData>
      </OrganisationData>
    </Account>
  );
};

export default App;
