import { FaEllipsisH, FaTrash } from "react-icons/fa";
import { OverlayTrigger } from "react-bootstrap";
import { Popover } from "react-bootstrap";
import ShowroomService from "services/ShowroomService";
import React, {useState} from "react";
import { SpinnerLoader } from "components/globals";

const LogoGrid = ({
  logos,
  setSelectedLogo,
  selectedLogo,
  token,
  setCurrentOptions,
}) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = () => {
    setLoading(true);
    if (token && selectedLogo) {
      const config = {
        headers: {
          Authorization: token,
        },
      };
      ShowroomService.delete(
        `/organizations/logos/${selectedLogo.id}`,
        config
      ).then((response) => {
        if (response.status === 200) {
          const logosUpdate = logos.filter(
            (logo) => logo.id !== selectedLogo.id
          );
          
          setCurrentOptions(logosUpdate);
        }
      }).catch(error => {
        //error
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  const renderPopover = (props) => (
    <Popover className="floating-menu-logo" {...props}>
      <h5 className="koulen-regular mx-2 mt-2">{selectedLogo.alias}</h5>
      <hr />
      <>{selectedLogo.type === "placeholder" ?
      <div className="w-100 h-100 d-flex ms-2 koulen-regular">
        Syncing to database...<br />
        Refresh the page
      </div>: <>{!loading ? <div
        className="clickable-action mx-2 lato-regular-16 d-flex align-items-center"
        onClick={handleDelete}
      >
        <FaTrash color="black" />
        Remove Image
      </div>: <SpinnerLoader />}</>}</>
    </Popover>
  );

  return (
    <>
      {logos?.map((logo, index) => {
        return (
          <React.Fragment key={logo.id}>
            {logo?.path?.length > 0 && (
              <div className="product-card-container">
                <div className="product-card-image d-flex flex-direction-column justify-content-end">
                   <OverlayTrigger
                      placement="bottom"
                      trigger="click"
                      overlay={renderPopover}
                    >
                      <div
                        className="ellpisis-icon-img logo"
                        onClick={() => setSelectedLogo(logo)}
                      >
                        <FaEllipsisH color="white" />
                      </div>
                    </OverlayTrigger>
                  
                  <img
                    src={logo.path}
                    className="w-100 h-100"
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <h5 className="koulen-regular">{logo.alias}</h5>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default LogoGrid;
