import { useNavigate } from "react-router-dom";
import { ShowAlert, SpinnerLoader } from "components/globals";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useContext } from "react";
import { OrganisationDataContext } from "components/providers/OrganisationDataProvider";
import { FaTshirt } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { useShowroomServiceFetch } from "hooks";
import { NavLink } from "react-router-dom";
import ShowroomService from "services/ShowroomService";
import { AccountContext } from "../components/providers/Account";
import { v4 as uuidv4 } from "uuid";
import { Spinner } from "react-bootstrap";

const HomePage = () => {
  const [name, setName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [token, setToken] = useState("");
  const [orgData, setOrgData] = useState(null);
  const [notificationIndex, setNotificationIndex] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [previewProjects, setPreviewProjects] = useState([]);
  const { data, error, loading } = useShowroomServiceFetch("/organizations/showrooms");
  const [venues, setVenues] = useState([]);
  const [refreshToken, setRefreshToken] = useState("");
  const { data: organisation, loading: orgLoading } = useContext(
    OrganisationDataContext
  );

  useEffect(() => {
    if (data?.length > 0) {
      if (data?.length > 3) {
        setPreviewProjects([
          data[0],
          data[1],
          data[2],
        ]);
      } else {
        setPreviewProjects(data);
      }
    }
  }, [data]);

  const navigate = useNavigate();

  const { getSession, updateAttributes, refreshSession } =
    useContext(AccountContext);

  useEffect(() => {
    setOrgData(organisation);
  }, [organisation]);

  useEffect(() => {
    getSession()
      .then((data) => {
        setToken(data.idToken.jwtToken);
      })
      .catch((error) => {
        error;
      });
  }, []);

  // const decrementNotification = () => {
  //   if (notificationIndex > 0) {
  //     setNotificationIndex(notificationIndex - 1);
  //   }
  // };

  // const incrementNotification = () => {
  //   if (notificationIndex < notifications.length - 1) {
  //     setNotificationIndex(notificationIndex + 1);
  //   }
  // };

  // const deleteNotification = (notification) => {
  //   const config = {
  //     headers: {
  //       Authorization: token,
  //     },
  //   };
  //   ShowroomService.delete(
  //     `users/notifications/${notification.notification_id}`,
  //     config
  //   ).then((response) => {
  //     if (response.status === 200) {
  //       notifications.splice(notificationIndex, 1);
  //       if (notificationIndex === notifications.length) {
  //         setNotificationIndex(notificationIndex - 1);
  //       }
  //       setNotifications([...notifications]);
  //     }
  //   });
  // };

  useLayoutEffect(() => {
    let idToken = null;
    let email = null;
    getSession().then((response) => {
      setToken(response.idToken.jwtToken);
      idToken = response.idToken.jwtToken;
      email = response.idToken.payload.email;

      setName(response.idToken.payload.name);

      const org_id = response?.idToken?.payload["custom:org_id"];
      if (!org_id) {
        ShowAlert({
          title: "Welcome to MantisXR",
          text: "You do not have a shopify storefront with your account. Would you like to associate one?",
          icon: "info",
          showConfirmButton: true,
          showDenyButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/dashboard/storefront");
          }
          if (result.isDenied) {
            const orgId = uuidv4();
            const body = JSON.stringify({
              name: orgId,
            });
            ShowroomService.post(`/organizations/users/${email}`, body, {
              Authorization: idToken,
            })
              .then((response) => {
                if (response.status === 200) {
                  updateAttributes(
                    response.data.organization.alias,
                    response.data.organization.name
                  )
                    .then(() => {
                      if (refreshToken) {
                        refreshSession(refreshToken)
                          .then((data) => {
                            setToken(data.idToken.jwtToken);
                            setRefreshToken(data.refreshToken.token);
                          })
                          .catch();
                      }
                    })
                    .catch((error) => {
                      //error
                    });
                }
              })
              .catch((error) => {
                //error
              });
          }
        });
      } else {
        setOrgId(org_id);
      }
      ShowroomService.get("/venues").then((response) => {
        setVenues(response.data);
      });
    });
  }, []);

  return (
    <>
      <div className="main-heading">
        <div>
          <h2 className="mt-1">{`Let's build together, ${name}`}</h2>
        </div>
        <div
          id="project-buttton"
          className="btn btn-outline-light lato-regular mt-3"
          onClick={() => navigate("/dashboard/projects/create")}
        >
          {" "}
          + New Project
        </div>
      </div>
      <div id="projects-preview" className="koulen-regular mt-1">
        <h4>Your Projects</h4>
        <div id="project-cards">
          {data?.length > 0 ? (
            <>
              {previewProjects?.map((room) => {
                return (
                  <React.Fragment key={room.id}>
                    <div className="preview-card-container">
                      <div
                        className="project-card"
                        key={room.alias}
                        style={{
                          backgroundImage: `url("${"https://3dmodelsbucket.s3.us-west-2.amazonaws.com/projects/mariners-suite/marinersPressClub.png"}")`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                      <p className="lato-regular">{room.name}</p>
                    </div>
                  </React.Fragment>
                );
              })}
            </>
          ) : (
            <div id="no-projects">
              {loading ? (
                <Spinner />
              ) : 
                <>
                  <h2>You currently do not have any projects</h2>
                  <div
                    className="btn btn-outline-light lato-regular mt-1"
                    onClick={() => navigate("/dashboard/projects/create")}
                  >
                    {" "}
                    + New Project
                  </div>
                </>
              }
            </div>
          )}
        </div>
        <div id="view-all-projects">
          <NavLink className="link-light clickable-text-large" to="myShowrooms">
            View All Projects→
          </NavLink>
        </div>
      </div>
      <div className="mt-1 main-heading d-flex flex-column">
        <h4>Recommended Venues</h4>
        
          {!loading && venues ? <div className="venues-cards-container">{
            venues?.length > 0 &&
            venues?.map((venue) => {
              return (
                <>
                  <div
                    className="venue-card d-flex flex-column"
                    key={venue.name}
                  >
                    <img className="venue-image" src={venue.preview_image} />
                    <div className="ms-1 d-flex flex-fill mt-2 venue-card-text">
                      <img
                        className="creator-image mt-1"
                        src={venue.creator.picture}
                      />
                      <div className="ms-2 d-flex flex-column lato-regular">
                        <div className="">{venue.name}</div>
                        <div className="font-size-10">
                          {venue.creator.organizations[0].organization.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            </div>: <SpinnerLoader />
            }
        </div>
      <div id="footer-container" className="mt-1">
        <div id="updates-container">
          <div className="d-flex justify-content-between">
            <div>
              <h5>Recent Updates</h5>
            </div>
            {notifications.length > 0 && (
              <div className="koulen-regular me-5">
                <FaChevronLeft
                  color="white"
                  className="me-1"
                  // onClick={decrementNotification}
                />
                {`${notificationIndex + 1} of ${
                  notifications.length === 0 ? 0 : notifications.length
                }`}
                <FaChevronRight
                  color="white"
                  className="ms-1"
                  // onClick={incrementNotification}
                />
              </div>
            )}
          </div>
          {notifications?.length > 0 &&
          notifications[notificationIndex]?.notification ? (
            <>
              <div className="notification-container p-2">
                <FaTshirt className="notification-icon" size={"5rem"} />
                <div id="notification-text" className="mx-4 lato-regular">
                  <div>
                    <h3 className="koulen-regular">
                      {notifications[notificationIndex].notification.title}
                    </h3>
                  </div>
                  <div>
                    {notifications[notificationIndex].notification.text}
                  </div>
                  <div className="koulen-regular">View it here</div>
                </div>
                <AiOutlineClose
                  // onClick={() =>
                  //   deleteNotification(notifications[notificationIndex])
                  // }
                />
              </div>
            </>
          ) : (
            <div className="text-center p-5">
              <h3>{"You're all caught up!"}</h3>
            </div>
          )}
        </div>
        <div id="integrations-container">
          <h5>Integrations</h5>
          <div id="integration-details" className="p-2">
            <div className="lato-regular font-size-16">
              Shoping Cart: {""}
              {orgData?.storefronts?.length > 0 ? "Shopify" : "None"}
            </div>
            <div>
              Status:{" "}
              {orgData?.storefronts?.length > 0
                ? "Connected✅"
                : "Not Integrated"}
            </div>
            <div>Products: {orgData?.products?.length || 0}</div>
            <NavLink to={"storefront"} className="link-light clickable">
              Modify
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
