import { Card, Col, Container, Row, Image } from "react-bootstrap";

const RegistrationLayout = ({ children, title }) => {
  return (
    <main className="d-flex w-100">
      <Container className="d-flex flex-column">
        <Row className="vh-100">
          <Col sm={10} md={8} lg={6} className="mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <Card className="shadow border-0">
                <div className="text-center mt-4">
                  <h1 className="h2">{title}</h1>
                </div>
                <Card.Body>
                  <div className="m-sm-4">
                    <div className="text-center">
                      <Image
                        src={require("../../utils/mocks/img/mantislogo.png")}
                        alt="Avatar"
                        className="img-fluid rounded-circle"
                        width="132"
                        height="132"
                      />
                    </div>
                    {children}
                  </div>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default RegistrationLayout;
