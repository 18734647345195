import { useContext, useLayoutEffect, useState } from "react";
import { Row, Tab, Tabs } from "react-bootstrap";
import { PasswordChange, ProfilePicture } from "components/AccountSettings";
import { DashboardLayout } from "components/layouts";
import { AccountContext } from "components/providers/Account";

const AccountPage = () => {
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");

  const { getSession } = useContext(AccountContext);

  useLayoutEffect(() => {
    getSession().then((data) => {
      setEmail(data.idToken.payload.email);
      setUserId(data.idToken.payload.sub);
      setToken(data.idToken.jwtToken);
    });
  }, []);

  return (
    <DashboardLayout>
      <Row>
        <Tabs
          defaultActiveKey="password"
          id="justify-password-tab"
          className="mb-3"
          variant="tabs"
        >
          <Tab eventKey="password" title="Change Password">
            <PasswordChange />
          </Tab>
          <Tab eventKey="picture" title="Profile Picture">
            <ProfilePicture email={email} userId={userId} token={token} />
          </Tab>
        </Tabs>
      </Row>
    </DashboardLayout>
  );
};

export default AccountPage;
