import Client from "shopify-buy/index.unoptimized.umd";
import { ShowAlert } from "../components/globals";

const shopifySingleProduct = ({ shopDom, shopTok, shopProd }) => {
  return new Promise((resolve, reject) => {
    if (shopProd !== "") {
      try {
        const client = Client.buildClient({
          domain: shopDom,
          storefrontAccessToken: shopTok,
        });
        const productsQuery = client.graphQLClient.query((root) => {
          root.addConnection(
            "products",
            { args: { first: 1, query: shopProd } },
            (product) => {
              product.add("id");
              product.add("vendor");
              product.add("description");
              product.add("productType");
              product.add("onlineStoreUrl");
              product.add("createdAt");
              product.add("handle");
              product.add("title");
              product.add("tags");
              product.add("options", function (opt) {
                opt.add("name");
                opt.add("values");
              });
              product.add("images", { args: { first: 6 } }, function (images) {
                images.add("pageInfo", function (pageInfo) {
                  pageInfo.add("hasNextPage");
                  pageInfo.add("hasPreviousPage");
                });
                images.add("edges", function (edges) {
                  edges.add("cursor");
                  edges.add("node", function (node) {
                    node.add("id");
                    node.add("url", {
                      alias: "src",
                    });
                    node.add("altText");
                    node.add("width");
                    node.add("height");
                  });
                });
              });
            }
          );
        });

        client.graphQLClient.send(productsQuery).then(({ data }) => {
          if (data === undefined) {
            ShowAlert({
              title: "Ooppss...!!",
              text: "Sorry, we could not find that product, please check the domain and storefront token combination",
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          }

          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    }
  });
};

export default shopifySingleProduct;
