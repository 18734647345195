import { useEffect } from "react";
import { useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";

const Shift = ({ selectedProduct }) => {
  const { camera } = useThree();
  const viewing_position = selectedProduct?.viewing_position || [];
  const viewing_rotation = selectedProduct?.viewing_rotation || [];
  const position = selectedProduct?.position || [];

  useEffect(() => {
    if (viewing_position.length > 0 && viewing_rotation.length > 0) {
      camera.position.set(
        viewing_position[0],
        viewing_position[1],
        viewing_position[2]
      );
      camera.rotation.set(
        viewing_rotation[0],
        viewing_rotation[1],
        viewing_rotation[2]
      );
      camera.updateProjectionMatrix();
    }
  }, [selectedProduct, camera]);

  return (
    <OrbitControls
      target={selectedProduct?.location ? position : [0, 0, -7]}
      initial_position={selectedProduct?.position || [3, 1, -5]}
      initial_rotation={selectedProduct?.viewing_rotation || [0, 0, 0]}
      enablePan={false}
      enableDamping={false}
      enableZoom
      enableRotate
      maxPolarAngle={Math.PI / 2}
      minPolarAngle={0}
      dampingFactor={0.1}
      rotateSpeed={0.2}
    />
  );
};

export default Shift;
