import { Form } from "react-bootstrap";

const ProductSelector = ({ products, setSelectedProduct }) => {
  const onChange = (event) => {
    if (event.target.value !== "Filter by Product") {
      const product = JSON.parse(event.target.value);
      setSelectedProduct(product);
    } else {
      setSelectedProduct(null);
    }
  };

  return (
    <>
      {products?.length > 0 && (
        <Form.Select onChange={onChange} className="filter-input">
          <option>Filter by Product</option>
          {products.map((product) => {
            return (
              <option key={product.id} value={JSON.stringify(product)}>
                {product.name}
              </option>
            );
          })}
        </Form.Select>
      )}
    </>
  );
};

export default ProductSelector;
