import { useRef, useState } from "react";
import { Col, Form, Row, Image } from "react-bootstrap";
import { Button, ShowAlert } from "../globals";
import * as IoIcons from "react-icons/io";
import ShowroomService from "../../services/ShowroomService";
import Service from "../../services";

const ProfilePicture = ({ userId, token, email }) => {
  const [image, setImage] = useState("");
  const [filename, setFilename] = useState("");
  const [file, setFile] = useState(null);

  const fileRef = useRef(null);

  const handleChangeFile = (event) => {
    event.preventDefault();

    const file = event.target.files;

    const fileReader = new FileReader();

    fileReader.readAsDataURL(file[0]);

    fileReader.onload = (event) => {
      setImage(event.target.result);
    };

    setFilename(file[0].name);
    setFile(file[0]);
  };

  const handleRemove = () => {
    setImage("");

    if (null !== fileRef.current) {
      fileRef.current.value = "";
    }
  };

  const handleProfilePictureSubmit = () => {
    const body = {
      filename,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    Service.put(`/users/${userId}/update-profile-picture`, body, config)
      .then((response) => {
        fetch(response.data.uploadURL, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": "image/jpeg",
          },
        })
          .then((responseS3) => {
            if (responseS3.status === 200) {
              const urlS = responseS3.url.split("?");

              const body = {
                picture: urlS[0],
              };

              ShowroomService.put(`/users/${email}`, body)
                .then((response) => {
                  if (response.status === 200) {
                    ShowAlert({
                      title: "Success...!!!",
                      text: "You have changed your profile picture",
                      icon: "success",
                      showConfirmButton: false,
                      timer: 3000,
                    }).then(() => {
                      window.location.reload();
                    });
                  }
                })
                .catch((error) => {
                  if (error) {
                    ShowAlert({
                      title: "Ooppss...!!",
                      text: "Something went wrong",
                      icon: "error",
                      showConfirmButton: false,
                      timer: 2000,
                    });
                  }
                });
            }
          })
          .catch((err) => {
            if (err) {
              ShowAlert({
                title: "Ooppss...!!",
                text: "Something went wrong",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          });
      })
      .catch((error) => {
        if (error) {
          ShowAlert({
            title: "Ooppss...!!",
            text: "Something went wrong I",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
  };

  return (
    <>
      <Form>
        <Row>
          <h3>Profile Picture</h3>
        </Row>
        <hr />
        <Row className="mt-3">
          <Col>
            <input
              ref={fileRef}
              type="file"
              multiple={false}
              onChange={handleChangeFile}
            />
            {image && (
              <button style={{ border: "none", background: "none" }}>
                <IoIcons.IoMdClose onClick={handleRemove} />
              </button>
            )}
          </Col>
        </Row>
        <hr />
        <Row className="mt-5">
          <Col lg={{ span: 6, offset: 5 }}>
            <Image
              rounded={true}
              style={{ width: "200px", height: "auto" }}
              src={image}
            />
          </Col>
        </Row>
        <Row>
          <Button onClick={handleProfilePictureSubmit}>Change Picture</Button>
        </Row>
      </Form>
    </>
  );
};

export default ProfilePicture;
