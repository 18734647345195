/* eslint-disable unused-imports/no-unused-imports */
import { useContext, useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import ShowroomService from "../../services/ShowroomService";
import { AccountContext, AuthInitType } from "../providers/Account";
import ShowAlert from "./Alert";
import Button from "./Button";
window.Buffer = window.Buffer || require("buffer").Buffer;
import * as IoIcons from "react-icons/io";

const GlbImportComponent = () => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [token, setToken] = useState("");
  const [orgId, setOrgId] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);

  const { getSession } = useContext(AccountContext);

  useEffect(() => {
    getSession()
      .then((data) => {
        setOrgId(data.idToken.payload["custom:org_id"]);

        setToken(data.accessToken.jwtToken);
      })
      .catch((error) => {
        error;
      });
  }, []);

  const fileRef = useRef < HTMLInputElement > null;

  const checkFileName = (name) => {
    const acceptableFileName = "glb";
    const fileChecked = acceptableFileName.includes(
      name.split(".").pop().toLowerCase()
    );

    return fileChecked;
  };

  const handleFile = async (event) => {
    const myFile = event.target.files[0];

    if (!myFile) return;

    if (!checkFileName(myFile.name)) {
      alert("Invalid File Type");

      if (null !== fileRef.current) {
        fileRef.current.value = "";
      }

      return;
    }

    setFileName(myFile.name);
    setFile(myFile);
  };

  const handleSubmit = () => {
    try {
      setButtonLoader(true);

      const body = {
        source: "mantis",
      };

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const fileNoExtension = fileName.split(".");

      ShowroomService.put(
        `organizations/${orgId}/products/${fileNoExtension[0]}/add-model`,
        body,
        config
      )
        .then((response) => {
          fetch(response.data.uploadURL, {
            method: "PUT",
            body: file,
            headers: {
              "Content-Type": "model/gltf-binary",
            },
          })
            .then((responseS3) => {
              if (responseS3.status === 200) {
                ShowAlert({
                  title: "Success!!",
                  text: "You've successfully uploaded the file!",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
              setButtonLoader(false);
              window.location.reload();
            })
            .catch((errorS3) => {
              ShowAlert({
                title: "Oooopsss.............!!!",
                text: `${errorS3.message}`,
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
              });
              setButtonLoader(false);
            });
        })
        .catch((err) => {
          ShowAlert({
            title: "Oooopsss.............!!!",
            text: `${err.message}`,
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        });
    } catch (error) {
      if (error) {
        ShowAlert({
          title: "Oooopsss.............!!!",
          text: "Something went wrong",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const handleRemove = () => {
    setFile(null);
    setFileName("");

    if (null !== fileRef.current) {
      fileRef.current.value = "";
    }
  };

  return (
    <Row>
      <div>
        {!fileName && (
          <input
            ref={fileRef}
            type="file"
            multiple={false}
            onChange={(event) => handleFile(event)}
          />
        )}
        {fileName && (
          <>
            <button style={{ border: "none", background: "none" }}>
              {fileName} <IoIcons.IoMdClose onClick={handleRemove} />
            </button>
            <Button
              isLoading={buttonLoader}
              style="btn btn-outline-primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </>
        )}
      </div>
    </Row>
  );
};

export default GlbImportComponent;
