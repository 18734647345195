import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import { createContext, useState } from "react";
import UserPool from "../../config/UserPool";

const defaultVars = {
  role: "",
  is_signed: true,
};

const AccountContext = createContext(null);

const Account = (props) => {
  const [auth, setAuth] = useState(defaultVars);

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();

      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            const attributes = new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};

                  for (const attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }

                  resolve(results);
                }
              });
            });

            resolve({ user, ...session, ...attributes });
            setAuth({ is_signed: true });
          }
        });
      } else {
        setAuth({ is_signed: false });
      }
      reject();
    });
  };

  const register = async (email, password, firstName, lastName) => {
    return await new Promise((resolve, reject) => {
      const attributeList = [
        new CognitoUserAttribute({
          Name: "email",
          Value: email,
        }),
        new CognitoUserAttribute({
          Name: "given_name",
          Value: firstName,
        }),
        new CognitoUserAttribute({
          Name: "family_name",
          Value: lastName,
        }),
        new CognitoUserAttribute({
          Name: "name",
          Value: `${firstName} ${lastName}`,
        }),
      ];
      UserPool.signUp(
        email,
        password,
        attributeList,
        attributeList,
        (err, result) => {
          if (err) {
            reject(err);
          }
          if (result) {
            resolve(result);
          }
        }
      );
    });
  };

  const confirmEmail = async (email, code) => {
    return await new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });
      cognitoUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          reject(err);
        }
        if (result) {
          resolve(result);
        }
      });
    });
  };

  const updateAttributes = async (org_id, org_name) => {
    const cognitoUser = UserPool.getCurrentUser();
    if (cognitoUser) {
      await new Promise((res) => cognitoUser.getSession(res));
      return new Promise((resolve, reject) => {
        const customAttributes = [
          new CognitoUserAttribute({
            Name: "custom:org_id",
            Value: org_id,
          }),
          new CognitoUserAttribute({
            Name: "custom:org_name",
            Value: org_name,
          }),
        ];

        cognitoUser.updateAttributes(customAttributes, (err, result) => {
          if (err) {
            reject(err);
          }
          if (result) {
            resolve(result);
          }
        });
      });
    }
  };

  const refreshSession = async (token) => {
    return await new Promise((resolve, reject) => {
      const cognitoUser = UserPool.getCurrentUser();
      const refreshToken = new CognitoRefreshToken({ RefreshToken: token });
      cognitoUser?.refreshSession(refreshToken, (err, result) => {
        if (err) {
          reject(err);
        }
        if (result) {
          resolve(result);
        }
      });
    });
  };

  const resendVerificationCode = async (email) => {
    return await new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err);
        }
        if (result) {
          resolve(result);
        }
      });
    });
  };

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool: UserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          setAuth({
            is_signed: true,
          });
          resolve(data);
        },
        onFailure: (err) => {
          setAuth({
            role: "",
            is_signed: false,
          });
          reject(err);
        },
        // newPasswordRequired: (data) => {
        // console.log("New Pass: ", data);
        // },
      });
    });
  };

  const changePassword = async (Username, oldPassword, newPassword) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool: UserPool,
      });

      user.changePassword(oldPassword, newPassword, (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });
  };

  const passwordReset = async (Username) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool: UserPool,
      });

      user.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },

        onFailure: (error) => {
          reject(error);
        },
      });
    });
  };

  const passwordChangeWithCode = async (Username, code, password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool: UserPool,
      });

      user.confirmPassword(code, password, {
        onSuccess: (data) => {
          resolve(data);
        },

        onFailure: (error) => {
          reject(error);
        },
      });
    });
  };

  const profilePicture = async (Username, picture) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool: UserPool,
      });

      const attributes = [
        new CognitoUserAttribute({
          Name: "picture",
          Value: picture,
        }),
      ];

      user.updateAttributes(attributes, (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });
  };

  const logout = () => {
    const user = UserPool.getCurrentUser();

    if (user) {
      user.signOut();
    }
  };

  return (
    <AccountContext.Provider
      value={{
        auth,
        register,
        authenticate,
        confirmEmail,
        resendVerificationCode,
        updateAttributes,
        refreshSession,
        getSession,
        logout,
        passwordReset,
        passwordChangeWithCode,
        changePassword,
        profilePicture,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };