import { useContext, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AccountContext } from "../components/providers/Account";

const PrivateRoute = () => {
  const { auth, getSession } = useContext(AccountContext);
  const location = useLocation();
  const { is_signed } = auth;

  useEffect(() => {
    getSession()
      .then((data) => {
        data;
      })
      .catch((error) => {
        error;
      });
  }, []);

  return is_signed ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
