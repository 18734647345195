/* eslint-disable react/no-unknown-property */
import { useEffect, useRef, useState, Suspense } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Button } from "components/globals";
import { ShowAlert } from "components/globals";
import AssetService from "services/AssetService";
import ShowroomService from "services/ShowroomService";
import { Canvas } from "@react-three/fiber";
import ProductPreview from "components/globals/ProductPreview";
import { OrbitControls } from "@react-three/drei";

const GeometryForm = () => {
  const [authToken, setAuthToken] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const [geometries, setGeometries] = useState([]);
  const [availableGeometries, setAvailableGeometries] = useState(false);
  const fileRef = useRef < HTMLInputElement > null;

  const acceptableFileName = "glb";

  const checkFileName = (name) => {
    const fileChecked = acceptableFileName.includes(
      name.split(".").pop().toLowerCase()
    );

    return fileChecked;
  };

  const handleFile = async (event) => {
    const myFile = event.target.files[0];
    const paths = event.target.value.split("\\");
    const name = paths[2].split(".")[0];
    setName(name);
    if (!myFile) return;

    if (!checkFileName(myFile.name)) {
      ShowAlert({
        title: "Error",
        text: "Invalid File Type!",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });

      if (null !== fileRef.current) {
        fileRef.current.value = "";
      }

      return;
    }
    setFile(myFile);
  };

  const handleSubmit = () => {
    setButtonLoader(true);

    const body = {
      name: name,
      type: "geometry",
    };
    AssetService.put("assetsUpload", body)
      .then((response) => {
        fetch(response.data.uploadURL, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": "model/gltf-binary",
          },
        })
          .then((responseS3) => {
            if (responseS3.status === 200) {
              ShowAlert({
                title: "Success!!",
                text: "You've successfully uploaded the file!",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
              });
            }
            setButtonLoader(false);
          })
          .catch((errorS3) => {
            ShowAlert({
              title: "Oooopsss.............!!!",
              text: `${errorS3.message}`,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
            setButtonLoader(false);
          });
      })
      .catch((err) => {
        ShowAlert({
          title: "Oooopsss.............!!!",
          text: `${err.message}`,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  useEffect(() => {
    ShowroomService.get("geometries")
      .then((response) => {
        const geometry = { id: 0, name: "" };
        const geometries = response.data;
        if (geometries.length > 0) {
          setAvailableGeometries(true);
        }
        setGeometries(geometries);
      })
      .catch();
  }, []);

  return (
    <>
      <Row className="text-center">
        <Col>
          <h5>Create your own 3D Model</h5>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={12}>
          <Form.Group>
            <Form.Control
              type="file"
              ref={fileRef}
              placeholder="Please add a glb model file"
              onChange={(event) => handleFile(event)}
            />
          </Form.Group>
        </Col>
      </Row>
      {file && (
        <div style={{ height: "30vh" }}>
          <Canvas>
            <Suspense fallback={null}>
              <OrbitControls
                enablePan={false}
                enableDamping={false}
                enableZoom
                enableRotate
                target={[0, 0, 2.6]}
              />
              <ambientLight intensity={0.6} />
              <ProductPreview
                geometry={file}
                geometryType="file"
                textureUrl=""
              />
            </Suspense>
          </Canvas>
        </div>
      )}
      <Row className="mt-1">
        <Col lg={3}>
          <Button
            isLoading={buttonLoader}
            style="btn btn-outline-primary"
            onClick={handleSubmit}
          >
            {" "}
            Submit{" "}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default GeometryForm;
