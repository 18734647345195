const productFilter = ({ products, nameInput }) => {
  let filteredData;

  if (nameInput.length > 0) {
    filteredData = products.filter(
      (product) =>
        product.name
          .toLocaleLowerCase()
          .indexOf(nameInput.toLocaleLowerCase()) !== -1
    );
  } else {
    filteredData = products;
  }

  return filteredData;
};

export default productFilter;
