/* eslint-disable react/no-unknown-property */
import { Html } from "@react-three/drei";

export const CanvasTooltip = ({ position, rotation, hover, message }) => {
  return (
    <group position={position} rotation={rotation}>
      <Html
        style={{
          transition: "all 0.2s ease",
          opacity: hover ? 1 : 0,
          transform: `scale(${hover ? 1.1 : 1})`,
          transformOrigin: "center center",
          translate: "20% -50%",
          fontFamily: "DM Sans",
          pointerEvents: "none",
          textAlign: "center",
          color: "white",
          width: "150px",
          fontSize: "12px",
          background: "rgba(0,0,0,0.5)",
          padding: "10px 20px",
          borderRadius: "50px",
        }}
        sprite={true}
      >
        {message}
      </Html>
    </group>
  );
};
