import { createContext } from "react";
import { useGetGeometries } from "hooks/useGetGeometries";

const GeometryDataContext = createContext(null);

const GeometryData = (props) => {
  const { geometries } = useGetGeometries();

  return (
    <GeometryDataContext.Provider
      value={{
        geometries,
      }}
    >
      {props.children}
    </GeometryDataContext.Provider>
  );
};

export { GeometryData, GeometryDataContext };
