import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { AccountContext } from "./Account";
import ShowroomService from "services/ShowroomService";

const OrganisationDataContext = createContext(null);

const OrganisationData = (props) => {
  const { getSession } = useContext(AccountContext);
  const [token, setToken] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useLayoutEffect(() => {
    setLoading(true);
    getSession()
      .then((response) => {
        if (response?.idToken?.jwtToken) {
          setToken(response.idToken.jwtToken);
        }
      })
      .catch((error) => setError(error));
  }, []);

  const getOrgData = async () => {
    if (token?.length > 0) {
      const config = {
        headers: {
          Authorization: token,
        },
      };
      ShowroomService.get("/organizations/details", config)
        .then((result) => {
          setData(result.data);
        })
        .catch((error) => setError(error))
        .finally(setLoading(false));
    } else {
      setData(null);
    }
  };

  useEffect(() => {
    getOrgData();
  }, [token]);

  const refetchData = () => {
    getOrgData();
  };

  return (
    <OrganisationDataContext.Provider
      value={{
        data,
        error,
        loading,
        refetchData,
      }}
    >
      {props.children}
    </OrganisationDataContext.Provider>
  );
};

export { OrganisationData, OrganisationDataContext };
