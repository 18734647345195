import { useState, useContext, useRef, useEffect } from "react";
import { Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Button, ShowAlert } from "components/globals";
import { RegistrationLayout } from "components/layouts";
import { AccountContext } from "components/providers/Account";
import { useSearchParams, useNavigate } from "react-router-dom";

const VerificationPage = (source) => {
  const [token] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("username");
  const verificationCodeRef = useRef(null);

  const { confirmEmail, resendVerificationCode } = useContext(AccountContext);

  useEffect(() => {
    if (token) {
      window.parent.postMessage(
        JSON.stringify({
          type: "Authenticated",
          token,
        }),
        "*"
      );
    }
  }, [token]);

  const handleResendVerification = () => {
    if (email) {
      resendVerificationCode(email)
        .then((data) => {
          if (data) {
            ShowAlert({
              title:
                "A new verification code has been sent to the provided email!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          }
        })
        .catch((err) => {
          if (err) {
            ShowAlert({
              title: "Oooopsss.............!!!",
              text: `${err.message}`,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        });
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (null !== verificationCodeRef.current && email) {
      confirmEmail(email, verificationCodeRef.current.value)
        .then((data) => {
          if (data) {
            ShowAlert({
              title: "You email for MantisXR is now verified!",
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            }).then(() => {
              if (source === "toolbox") {
                navigate("/toolbox");
              } else {
                navigate("/dashboard");
              }
            });
          }
        })
        .catch((err) => {
          if (err) {
            ShowAlert({
              title: "Oooopsss.............!!!",
              text: `${err.message}`,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        });
    }
  };

  return (
    <RegistrationLayout title="Email Verification">
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Verification Code</Form.Label>
          <Form.Control
            ref={verificationCodeRef}
            required
            type="text"
            placeholder="Code"
          />
        </Form.Group>
        <div className="d-flex justify-content-between">
          <NavLink
            className="clickable-action link-body-emphasis link-offset-2 link-underline-opacity-25 link-underline-opacity-75-hover"
            to="/login"
          >
            Back
          </NavLink>
          <a
            className="clickable-action link-body-emphasis link-offset-2 link-underline-opacity-25 link-underline-opacity-75-hover"
            onClick={handleResendVerification}
          >
            <u>Resend Verification Code</u>
          </a>
        </div>
        <Button type="submit">Verify</Button>
      </Form>
    </RegistrationLayout>
  );
};

export default VerificationPage;
