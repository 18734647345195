import { FaArrowRight } from "react-icons/fa";

export const ModalSelectedVenue = ({ venue, onMouseLeave, onViewChange }) => {
  return (
    <div className="w-100 h-100" onMouseLeave={onMouseLeave}>
      <h5 className="koulen-regular mt-2 ms-2">{venue?.name}</h5>
      <p className="lato-regular body-text ms-2 mt-1">{`View More by ${venue.creator.organizations[0].organization.name}`}</p>
      <div id="tag-container" className="d-flex flex-wrap">
        {venue.tags?.map((tag) => {
          return (
            <div
              key={tag}
              className="modal-tag lato-regular border border-dark rounded ms-1 px-2"
            >
              {tag}
            </div>
          );
        })}
      </div>
      <div
        onClick={onViewChange}
        className="clickable-text-large d-flex koulen-regular justify-content-end mb-1 p-1 align-items-center"
      >
        Use Venue <FaArrowRight />
      </div>
    </div>
  );
};
