import { Button, ShowAlert } from "components/globals";
import { AccountContext } from "components/providers/Account";
import {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useContext,
  useCallback,
} from "react";
import { Col, Form, Row } from "react-bootstrap";
import ShowroomService from "services/ShowroomService";
import { multiThreadFetch } from "utils/functions";
import jwt from "jwt-decode";
import StorefrontTable from "./StorefrontTable";
import { useShopifyClient } from "hooks";
import { useNavigate } from "react-router-dom";

const StorefrontForm = () => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [orgId, setOrgId] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const navigate = useNavigate();
  const [storefrontArray, setStorefrontArray] = useState([]);
  const [token, setToken] = useState("");
  const [storefrontId, setStorefrontId] = useState(null);

  const domainRef = useRef(null);
  const tokenRef = useRef(null);

  const { getSession, updateAttributes, refreshSession } =
    useContext(AccountContext);
  const { clientVerifier } = useShopifyClient();

  useLayoutEffect(() => {
    getSession().then((data) => {
      setToken(data.idToken.jwtToken);
      const org_id = data?.idToken?.payload["custom:org_id"];
      setOrgId(org_id);
    });
  }, []);

  const onDelete = useCallback(() => {
    if (storefrontId) {
      ShowAlert({
        title: "Hey...!!!",
        text: "By deleting this info you may cause side effects on products you have in showrooms",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          const config = {
            headers: {
              Authorization: token,
            },
          };

          ShowroomService.delete(`/storefronts/${storefrontId}`, config)
            .then((response) => {
              ShowAlert({
                title: "Deleted",
                text: "Item succesfully deleted",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
              });
              memoizedData();
            })
            .catch((error) => {
              ShowAlert({
                title: "Ooopppsss...!!!",
                text: "Please try again, otherwise contact your manager",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
              });
            });
        } else {
          setStorefrontId(null);
        }
      });
    }
  }, [storefrontId]);

  useEffect(() => {
    onDelete();
  }, [onDelete]);

  const storefrontProviderSubmitHandler = async () => {
    if (domainRef.current && tokenRef.current) {
      setButtonLoader(true);
      let domain = domainRef.current.value;

      if (domainRef.current.value.includes("https://")) {
        const newValue = domainRef.current.value.replace("https://", "");

        domain = newValue;
      }

      const config = {
        headers: {
          Authorization: token,
        },
      };

      const body = {
        metadata: {
          domain,
          token: tokenRef.current.value,
        },
        type: "shopify",
      };

      if (token.length) {
        const client = await clientVerifier({
          domain,
          storefrontAccessToken: tokenRef.current.value,
        });

        if (!client) {
          return ShowAlert({
            title: "Ooopppsss...!!!",
            text: "Domain or storefront token are not valid",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
          });
        }

        if (orgId) {
          ShowroomService.post("/storefronts", body, config)
            .then((response) => {
              if (response.status === 200) {
                ShowAlert({
                  title: "Awesome...!!!",
                  text: "You have added new online credentials to your account",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
                navigate("/dashboard");
              }
              memoizedData();
            })
            .catch((error) => {
              if (error.response.status === 500) {
                ShowAlert({
                  title: "Ooopppsss...!!!",
                  text: "We could not safe your data, please try again, otherwise contact you manager",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 3000,
                });
              }
            })
            .finally(() => {
              setButtonLoader(false);
            });
        } else {
          ShowroomService.post("/organizations/shopify", body, config)
            .then((response) => {
              if (response.status === 200) {
                ShowAlert({
                  title: "Awesome...!!!",
                  text: "You have added new online credentials to your account",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });
                updateAttributes(
                  response.data.organization.alias,
                  response.data.organization.name
                )
                  .then(() => {
                    if (refreshToken) {
                      refreshSession(refreshToken)
                        .then((data) => {
                          setToken(data.idToken.jwtToken);
                          setRefreshToken(data.refreshToken.token);
                        })
                        .catch()
                        .finally(() => {
                          navigate("/dashboard");
                        });
                    }
                  })
                  .catch((error) => {
                    //error
                  });
              }
            })
            .catch((error) => {
              if (error.response.status === 500) {
                ShowAlert({
                  title: "Error",
                  text: "We could not save your data, please try again, otherwise contact you manager",
                  icon: "error",
                  showConfirmButton: false,
                  timer: 3000,
                });
              }
            })
            .finally(() => {
              setButtonLoader(false);
            });
        }
        domainRef.current.value = "";
        tokenRef.current.value = "";
      }
    }
  };

  const memoizedData = () => {
    if (token.length) {
      const config = {
        headers: {
          Authorization: token,
        },
      };

      Promise.all([multiThreadFetch("/storefronts", config)])
        .then(([storefronts]) => {
          const storefrontTemp = [];

          for (let index = 0; index < storefronts.length; index++) {
            const element = storefronts[index];

            if (element.metadata.length) {
              const decoded = jwt(element.metadata);

              if (decoded) {
                decoded["type_id"] = element.type_id;
                decoded["id"] = element.id;
                storefrontTemp.push(decoded);
              }
            }
          }

          setStorefrontArray(storefrontTemp);
        })
        .catch((error) => {
          if (error.response.status === 502) {
            ShowAlert({
              title: "Ooopppsss...!!!",
              text: "We could not load the data, please try again otherwise contact your manager",
              icon: "error",
              showConfirmButton: false,
              timer: 3000,
            });
          }
        });
    }
  };

  useEffect(() => {
    memoizedData();
  }, [token]);

  return (
    <>
      <Form>
        <Row className="mb-3">
          <Col lg={6}>
            <h3>Add Online Store Provider</h3>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <h5>Please, select your online store from the dropdown</h5>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <h5>
              Provide the credentials of your online store to be linked to your
              showrooms and products.
            </h5>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="shopDom">
              <Form.Control
                ref={domainRef}
                required
                type="text"
                placeholder="Domain"
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="shopDom">
              <Form.Control
                ref={tokenRef}
                required
                type="text"
                placeholder="Storefront Token"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Button
            isLoading={buttonLoader}
            onClick={storefrontProviderSubmitHandler}
          >
            Add Provider
          </Button>
        </Row>
      </Form>
      <hr />
      <Row>
        <StorefrontTable
          setStorefrontId={setStorefrontId}
          storefrontArray={storefrontArray}
        />
      </Row>
    </>
  );
};

export default StorefrontForm;
