export const Instructions = () => {
  return (
    <div
      id="instructions"
      className="d-flex flex-column justify-content-center align-items-center"
    >
      <div id="ellipse-container">
        <div id="ellipse-1"></div>
        <div id="ellipse-2"></div>
        <div id="ellipse-3"></div>
        <div id="ellipse-4"></div>
      </div>
      <div id="instruction-text" className="lato-regular text-light">
        Select your venue to get started
      </div>
    </div>
  );
};
