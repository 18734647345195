import { RiDragDropLine } from "react-icons/ri";
import { FaArrowsRotate } from "react-icons/fa6";

export const ProductPlacementModal = ({
  placementModalOpen,
  onPlacementModal,
  addProductToPosition,
  location,
  modalMode,
  productsInPositions,
}) => {
  const allowDrop = (event) => {
    event.preventDefault();
  };

  const handleDrop = () => {
    addProductToPosition(location);
    onPlacementModal(false);
  };

  const handleClick = (location) => {
    addProductToPosition(location);
    onPlacementModal(false);
  };

  if (!placementModalOpen) return null;

  return (
    <div id="placement-modal">
      {modalMode === "dropbox" && (
        <div
          id="dropbox"
          onDragOver={(event) => allowDrop(event)}
          onDrop={handleDrop}
        >
          <RiDragDropLine color="white" size={50} />
          <p className="koulen-regular text-light mt-2 mx-1">Drag model here</p>
        </div>
      )}
      {modalMode === "buttons" && (
        <div className="d-flex flex-column">
          <p className="koulen-regular text-light">Select a location</p>
          <div className="d-flex flex-wrap">
            {productsInPositions.map((position) => {
              return (
                <div
                  id="location-btn"
                  onClick={() => handleClick(position.location)}
                  key={position.location}
                  className="btn btn-outline-light m-1"
                >
                  {position.location}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {modalMode === "editmodel" && <FaArrowsRotate size={20} color="white" />}
    </div>
  );
};
