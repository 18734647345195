import { useContext, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Button, ShowAlert } from "../components/globals";
import { DashboardLayout } from "../components/layouts";
import { AccountContext } from "../components/providers/Account";
import ShowroomService from "../services/ShowroomService";

const CreateOrganizationPage = () => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [token, setToken] = useState("");
  const { getSession } = useContext(AccountContext);

  const nameRef = useRef < HTMLInputElement > null;

  useEffect(() => {
    getSession()
      .then((data) => {
        setToken(data.accessToken.jwtToken);
      })
      .catch((error) => {
        error;
      });
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();

    setButtonLoader(true);

    if (null !== nameRef.current) {
      const name = nameRef.current.value;

      const body = {
        name,
      };

      const auth = {
        headers: {
          Authorization: token,
        },
      };

      try {
        ShowroomService.post("/organizations", body, auth)
          .then((response) => {
            if (response) {
              setButtonLoader(false);

              ShowAlert({
                title: "New Organization!",
                text: `You have created the Organization: ${response.data.name}`,
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          })
          .catch((error) => {
            setButtonLoader(false);

            const { message } = error;

            ShowAlert({
              title: "Oopss...! Something went wrong!",
              text: `${message}`,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
          });

        nameRef.current.value = "";
      } catch (error) {
        setButtonLoader(false);

        if (error) {
          ShowAlert({
            title: "Oopss...! Something went wrong!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
    }
  };

  return (
    <DashboardLayout>
      <Form onSubmit={onSubmit}>
        <Row>
          <h3 className="mb-3">Create New Organization</h3>
        </Row>
        <Row>
          <Col lg={8}>
            <p className="mb-5">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro
              maiores dolore beatae consequuntur illo odio reiciendis deserunt
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name of the new Organization</Form.Label>
              <Form.Control
                required
                ref={nameRef}
                type="name"
                placeholder="Enter name of New Organization"
              />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={12}>
            <Button isLoading={buttonLoader} type="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </DashboardLayout>
  );
};

export default CreateOrganizationPage;
