/* eslint-disable react/no-unknown-property */
import { useContext, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { ShowAlert } from "components/globals";
import { Button } from "components/globals";
import AssetService from "services/AssetService";
import { AccountContext } from "../providers/Account";
import { useNavigate } from "react-router-dom";

const ModelForm = ({ token, serial_number, org_id, origin }) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [name, setName] = useState("");
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);
  const { getSession } = useContext(AccountContext);
  const navigate = useNavigate();

  const acceptableFileName = "glb";

  const checkFileName = (name) => {
    const fileChecked = acceptableFileName.includes(
      name.split(".").pop().toLowerCase()
    );

    return fileChecked;
  };

  const handleFile = async (event) => {
    const myFile = event.target.files[0];
    const paths = event.target.value.split("\\");
    const name = paths[2].split(".")[0];
    setName(name);
    if (!myFile) return;

    if (!checkFileName(myFile.name)) {
      ShowAlert({
        title: "Error",
        text: "Invalid File Type!",
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });

      if (null !== fileRef.current) {
        fileRef.current.value = "";
      }

      return;
    }
    setFile(myFile);
  };

  const handleSubmit = () => {
    setButtonLoader(true);

    const body = {
      name: name,
      type: "model",
      productId: serial_number,
      orgId: org_id,
    };
    AssetService.put("assetsUpload", body)
      .then((response) => {
        fetch(response.data.uploadURL, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": "model/gltf-binary",
          },
        })
          .then((responseS3) => {
            if (responseS3.status === 200) {
              ShowAlert({
                title: "Success!!",
                text: "You've successfully uploaded the file!",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
              });
              if (origin) {
                navigate(`/dashboard/${origin}`);
              } else {
                navigate("/dashboard");
              }
            }
          })
          .catch((errorS3) => {
            ShowAlert({
              title: "Oooopsss.............!!!",
              text: `${errorS3.message}`,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
            setButtonLoader(false);
          });
      })
      .catch((err) => {
        ShowAlert({
          title: "Oooopsss.............!!!",
          text: `${err.message}`,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  return (
    <>
      <div className="w-90 m-1 d-flex flex-column justify-content-between">
        <h5 className="koulen-regular my-1 text-dark">Add Model File</h5>
        <Form.Control
          type="file"
          ref={fileRef}
          className="file-input-light"
          placeholder="Please add a glb model file"
          onChange={(event) => handleFile(event)}
        />
        <div className="mt-5">
          {file && (
            <Button
              isLoading={buttonLoader}
              style="btn btn-outline-dark"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default ModelForm;
