import { Spinner } from "react-bootstrap";

const Button = ({
  style = "btn btn-outline-dark rounded-pill",
  type = "button",
  onClick,
  children,
  divStyles = "d-grid gap-2 col-lg-6 offset-lg-3 text-center mt-3",
  isLoading = false,
}) => {
  return (
    <div className={divStyles}>
      {!isLoading ? (
        <button className={style} type={type} onClick={onClick}>
          {children}
        </button>
      ) : (
        <button className={style} disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Loading...
        </button>
      )}
    </div>
  );
};

export default Button;
