import { useState, useEffect, useContext, useRef } from "react";
import classes from "../../styles/_navbar.module.scss";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import { NavLink, useNavigate } from "react-router-dom";
import * as Ionicons5 from "react-icons/io5";
import { Sidebar } from "../Sidebar";
import { ShowAlert } from "../globals";
import { AccountContext } from "../providers/Account";
import { useSize } from "../../hooks";
import { Dropdown, Tooltip } from "react-bootstrap";
import "./navbar-style.css";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const tooltipRef = useRef(null);
  const navigate = useNavigate();
  const { logout } = useContext(AccountContext);

  const renderTooltip = (text) => (
    <Tooltip id="button-tooltip" style={{ position: "fixed" }}>
      {text}
    </Tooltip>
  );

  const size = useSize();

  useEffect(() => {
    if (size.width !== undefined && size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const menuToggleHandler = () => {
    setMenuOpen(!menuOpen);
  };

  const navigateHome = () => {
    navigate("/dashboard");
  };

  const navbarLogoutHandler = () => {
    ShowAlert({
      title: "Thank you for being part of MANTIS XR!",
      icon: "success",
      showConfirmButton: false,
      timer: 2000,
    }).then(() => {
      logout();
      navigate("/");
    });
  };

  return (
    <>
      <header id="nav" className="h-6">
        <div
          id="logo-container"
          className="d-flex flex-column justify-content-center"
          onClick={navigateHome}
        >
          <div className="d-flex ms-3" role="button">
            <div id="mantis-logo">
              <img
                src={
                  "https://3dmodelsbucket.s3.us-west-2.amazonaws.com/icons/Mantis.png"
                }
              ></img>
            </div>
            <div
              id="logo-text"
              className="koulen-regular font-16 align-self-center"
            >
              MANTIS XR
            </div>
          </div>
        </div>
        <div id="links" className="d-flex koulen-regular font-16 mx-3">
          <NavLink
            to="/dashboard/myShowrooms"
            className="koulen-regular font-16 mx-3 link"
            onClick={menuToggleHandler}
          >
            PROJECTS
          </NavLink>
          <NavLink
            to="/dashboard/products"
            className="koulen-regular font-16 mx-3 link"
            onClick={menuToggleHandler}
          >
            PRODUCTS
          </NavLink>
          <NavLink
            to="/dashboard/models"
            className="koulen-regular font-16 mx-3 link"
            onClick={menuToggleHandler}
          >
            MODELS
          </NavLink>
        </div>
        <nav
          id="buttons"
          className={`${classes.header__content__nav} ${
            menuOpen && size.width !== undefined && size.width < 768
              ? classes.isMenu
              : ""
          }`}
        >
          <ul>
            <li>
              <NavLink
                to="/dashboard/myShowrooms"
                className="koulen-regular font-16 m-link"
                onClick={menuToggleHandler}
              >
                PROJECTS
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/products"
                className="koulen-regular font-16 m-link"
                onClick={menuToggleHandler}
              >
                PRODUCTS
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/models"
                className="koulen-regular font-16 m-link"
                onClick={menuToggleHandler}
              >
                MODELS
              </NavLink>
            </li>
            <li>
              <Dropdown>
                <Dropdown.Toggle
                  as="a"
                  ref={tooltipRef}
                  className="dropdown-toggle"
                  id="dropdown-basic"
                >
                  <Ionicons5.IoPersonOutline size={22} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="koulen-regular drpdwn" variant="dark">
                  <Dropdown.Item
                    as={NavLink}
                    to="/dashboard/storefront"
                    onClick={menuToggleHandler}
                  >
                    Add Storefront
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to="/dashboard/logos"
                    onClick={menuToggleHandler}
                  >
                    Manage 2D Assets
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to="/dashboard/account"
                    onClick={menuToggleHandler}
                  >
                    My Account
                  </Dropdown.Item>
                  <Dropdown.Item onClick={navbarLogoutHandler}>
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </nav>
        <div className={classes.header__content__toggle} id="menu-button">
          {menuOpen ? (
            <AiOutlineClose onClick={menuToggleHandler} />
          ) : (
            <BiMenuAltRight onClick={menuToggleHandler} />
          )}
        </div>
      </header>
      <Sidebar />
    </>
  );
};

export default Navbar;