/* eslint-disable react/no-unknown-property */
/* eslint-disable no-undef */
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import {
  FaCheck,
  FaFolder,
  FaBoxes,
  FaEllipsisH,
  FaTrash,
} from "react-icons/fa";
import { Card, Form, Spinner } from "react-bootstrap";
import { Loader, Pagination, ShowAlert } from "../components/globals";
import { AccountContext } from "../components/providers/Account";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { useShowroomServiceFetch } from "hooks";
import { OverlayTrigger } from "react-bootstrap";
import { Popover } from "react-bootstrap";
import productFilter from "helpers/productFilter";

const needsAsset = 3;
const assetUploaded = 4;
const live = 1;

const StatusComponent = (status) => {
  switch (status) {
    case needsAsset:
      return (
        <>
          <b className="text-danger lato-regular">{" Needs 3D Model"}</b>
        </>
      );
    case assetUploaded:
      return (
        <>
          <b className="live-text lato-regular">
            <FaCheck color="#3BFA87" className="me-1" size="10px " />
            {"Live"}
          </b>
        </>
      );
    case live:
      return (
        <>
          <b className="live-text lato-regular">
            <FaCheck color="#3BFA87" size="10px" className="me-1" />
            {"Live"}
          </b>
        </>
      );
  }
};

const ProductDetailsPage = () => {
  const [organizationId, setOrganizationId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [optionsPerPage] = useState(8);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [token, setToken] = useState("");
  const filterInputRef = useRef(null);

  const navigate = useNavigate();

  const { getSession } = useContext(AccountContext);

  const { data, error, loading } = useShowroomServiceFetch(
    `organizations/${organizationId}/products`
  );

  const handleDelete = () => {
    setButtonLoading(true);
    if (selectedProduct && token) {
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/storefronts/${selectedProduct.storefront.id}/products/${selectedProduct.serial_number}`,
        {
          method: "DELETE",
          headers: {
            Authorization: token,
          },
        }
      ).then((response) => {
        if (response.status === 200) {
          ShowAlert({
            title: "Success...!!!",
            text: "You have removed the product",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            const newOptions = currentOptions.filter(
              (product) => product.id !== selectedProduct.id
            );
            setCurrentOptions([...newOptions]);
          });
        }
      }).catch(error => {
        //error
      }).finally(() => {
        setButtonLoading(false);
      });
    }
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (!loading) {
      setCurrentOptions(data);
      setFilteredProducts(data);
    }
  }, [loading]);

  useLayoutEffect(() => {
    getSession().then((data) => {
      setToken(data.idToken.jwtToken);
      setOrganizationId(data.idToken.payload["custom:org_id"]);
    });
  }, []);

  useEffect(() => {
    if (filteredProducts.length > 0) {
      const indexOfLastOption = currentPage * optionsPerPage;
      const indexOfFirstOption = indexOfLastOption - optionsPerPage;
      setCurrentOptions(
        filteredProducts.slice(indexOfFirstOption, indexOfLastOption)
      );
    } else {
      setCurrentOptions(data);
    }

    if (error.length > 0) {
      ShowAlert({
        title: "Ooppss...!!",
        text: error,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [data, error, loading, filteredProducts, currentPage]);

  const renderPopover = (props) => {
    return (
      <>
        <Popover className="floating-menu-logo" {...props}>
          <h5 className="koulen-regular mx-2 mt-2">{selectedProduct?.name}</h5>
          <hr />
          {!buttonLoading ?  <div
            className="clickable-action mx-2 lato-regular-16 d-flex align-items-center"
            onClick={handleDelete}
          >
            <FaTrash color="black" />
            Remove Product
          </div>: <div className="d-flex align-items-center justify-content-center"><Spinner /></div>}
        </Popover>
      </>
    );
  };

  useEffect(() => {
    const filteredDataByName = productFilter({
      products: data,
      nameInput: filterInput,
    });
    setFilteredProducts(filteredDataByName);
  }, [filterInput]);

  return (
    <>
      <div className="main-heading">
        <div>
          <h3 className="mt-2">Products</h3>
        </div>
        <div
          className="btn btn-outline-light lato-regular mt-2"
          onClick={() => navigate("/dashboard/upload-products?mode=products")}
        >
          Manage Products
        </div>
      </div>
      <div className="main-heading mt-1">
        <div className="w-50% search-bar">
          <FaSearch
            style={{ marginLeft: "1rem", position: "absolute" }}
            color="white"
          />
          <Form.Control
            className="search-input"
            ref={filterInputRef}
            onChange={(event) => {
              setFilterInput(event.target.value);
            }}
            placeholder={"Search"}
          />
        </div>
      </div>
      <div id="projects-preview" className="koulen-regular mt-3">
        <div id="view-project-cards">
          {data?.length > 0 ? (
            <>
              {currentOptions?.map((product) => {
                return (
                  <div
                    className="product-card-container mt-1"
                    key={product.serial_number}
                  >
                    <div className="product-card-image d-flex justify-content-end">
                      <OverlayTrigger
                        placement="bottom"
                        trigger="click"
                        onHide={() => this.setState({ show: false })}
                        rootClose={true}
                        rootCloseEvent="mousedown"
                        overlay={renderPopover}
                      >
                        <div
                          className="ellpisis-icon-img logo"
                          onClick={() => {
                            setSelectedProduct(product);
                          }}
                        >
                          <FaEllipsisH color="white" />
                        </div>
                      </OverlayTrigger>
                      <img
                        className="w-100 h-100"
                        src={
                          product?.products_data[0]?.url ||
                          "https://3dmodelsbucket.s3.us-west-2.amazonaws.com/default-product-image.png"
                        }
                      />
                    </div>
                    <Card.Body>
                      <Card.Title className="lato-regular card-title">
                        {product.name.length > 20
                          ? `${product.name.substr(0, 19)}...`
                          : product.name}
                      </Card.Title>
                      <Card.Text>
                        {StatusComponent(product.status_id)}
                      </Card.Text>
                      <div className="d-flex">
                        <FaFolder
                          color="white"
                          size="15px"
                          className="me-1 mt-1"
                        />
                        <p className="lato-regular">
                          {product?.showrooms?.length || 0}
                        </p>
                        <FaBoxes
                          color="white"
                          size={"15px"}
                          className="ms-2 me-1 mt-1"
                        />
                        <p className="lato-regular">
                          {product.attachment.length > 0
                            ? 1 + product.textures.length
                            : product.textures.length}
                        </p>
                      </div>
                    </Card.Body>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {loading ? (
                <Loader />
              ) : (
                <div id="no-projects">
                  <h2>You currently do not have any products</h2>
                  <div
                    className="btn btn-outline-light lato-regular mt-3"
                    onClick={() =>
                      navigate("/dashboard/upload-products?mode=products")
                    }
                  >
                    {" "}
                    + Add Product
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {filteredProducts.length > 1 && (
        <div className="main-heading main-pagination-container">
          <div className="pagination-container">
            <Pagination
              optionsPerPage={optionsPerPage}
              totalOptions={filteredProducts.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetailsPage;