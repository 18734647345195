import { ThreeCircles } from "react-loader-spinner";

const Loader = () => {
  return (
    <div className="text-center d-flex justify-content-center align-items-center w-100 h-100">
      {/* <RotatingSquare
        wrapperStyle={{ position: "absolute", top: "50%", left: "50%" }}
        ariaLabel="rotating-square-loading"
        visible={true}
        color="grey"
        strokeWidth="10"
      /> */}
      <ThreeCircles
        height="100"
        width="100"
        color="white"
        wrapperStyle={{ position: "absolute", top: "50%", left: "50%" }}
        wrapperClass=""
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
      />
    </div>
  );
};

export default Loader;
