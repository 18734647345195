import { ShowAlert } from "components/globals";
import ShowroomService from "services/ShowroomService";
import { useEffect, useState, useContext, useLayoutEffect } from "react";
import { AccountContext } from "components/providers/Account";

export const useGetGeometries = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [token, setToken] = useState(null);

  useLayoutEffect(() => {
    setLoading(true);
    if (token) {
      getSession()
        .then((response) => {
          setOrgId(response.idToken.payload["custom:org_id"]);
          setToken(response.idToken.jwtToken);
        })
        .catch();
    }
  }, []);

  const { getSession } = useContext(AccountContext);

  const fetchData = async () => {
    if (token && orgId) {
      const config = {
        headers: {
          Authorization: token,
        },
      };

      try {
        const response = await ShowroomService.get("/geometries", config);
        setData(response.data);
      } catch (error) {
        ShowAlert({
          title: "Oooopsss.............!!!",
          text: `Error: ${error}`,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  return {
    geometries: data,
  };
};