import { useContext, useLayoutEffect, useEffect, useState } from "react";
import { NavContext } from "../providers/NavProvider";
import { Image, Row } from "react-bootstrap";
import { AccountContext } from "../providers/Account";
import UserSidebar from "./UserSidebar";
import { NavLink } from "react-router-dom";
import { useShowroomServiceFetch } from "hooks";

const Sidebar = () => {
  const [size, setSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [profilePicture, setProfilePicture] = useState("");
  const [user, setUser] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [orgName, setOrgName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDesigner, setIsDesigner] = useState(false);

  const { sidebar, handleNav } = useContext(NavContext);
  const { getSession } = useContext(AccountContext);

  const { data: userData, error: userError } = useShowroomServiceFetch(
    `/users/${userEmail}`
  );
  const { data: permissionsData, error: permissionsError } =
    useShowroomServiceFetch("/permissions");

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useLayoutEffect(() => {
    getSession()
      .then((data) => {
        const { name } = data.user.signInUserSession.idToken.payload;
        const org =
          data.user.signInUserSession.idToken.payload["custom:org_name"];
        setOrgName(org);
        setUser(name);
        setUserEmail(data.idToken.payload.email);
      })
      .catch((error) => {
        //error;
      });
  }, []);

  useEffect(() => {
    permissionsData.map((permission) => {
      if (permission.id === userData?.permission_id) {
        if (permission.value === "Admin" && orgName === "MantisXR") {
          setIsAdmin(true);
        } else if (permission.value === "Designer" && orgName === "MantisXR") {
          setIsDesigner(true);
        }
      }
    });

    setProfilePicture(userData?.picture);
  }, [userData, userError, permissionsData, permissionsError]);

  return (
    <>
      <nav
        className={`${sidebar ? "nav-menu nav-menu--active" : "nav-menu"} ${
          sidebar && size.width < 768 ? "nav-menu--responsive" : ""
        }`}
      >
        <div className="container">
          <Row className="align-items-start">
            <div className="mt-3 text-center">
              <h1 className="text-light fw-light nav-menu__title">
                <NavLink
                  style={{ color: "white", textDecoration: "none" }}
                  to="/dashboard"
                  onClick={handleNav}
                >
                  <span>Mantis XR</span>
                </NavLink>
              </h1>
              <div className="nav-menu__line" />
            </div>
            <div className="text-center mt-5">
              <Image
                className="nav-menu__image"
                rounded={true}
                roundedCircle={true}
                src={profilePicture}
              />
              <div className="mt-2">
                <p className="text-light">
                  {user} - {orgName}
                </p>
              </div>
            </div>
          </Row>
          <Row className="align-items-center">
            <ul className="nav-menu__items" onClick={handleNav}>
              {<UserSidebar />}
            </ul>
          </Row>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
