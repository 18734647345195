import Client from "shopify-buy/index.unoptimized.umd";

const useShopifyClient = () => {
  const clientVerifier = ({ domain, storefrontAccessToken }) => {
    return new Promise((resolve, reject) => {
      try {
        const client = Client.buildClient({
          domain,
          storefrontAccessToken,
        });

        const products = client.graphQLClient.query((root) => {
          root.addConnection("products", { args: { first: 1 } }, (product) => {
            product.add("title");
          });
        });

        client.graphQLClient
          .send(products)
          .then(({ data }) => {
            if (data === undefined) {
              resolve(false);
            }

            resolve(true);
          })
          .catch((err) => {
            if (err) {
              resolve(false);
            }
            reject(err);
          });
      } catch (error) {
        resolve(false);
        reject(error);
      }
    });
  };

  return { clientVerifier };
};

export default useShopifyClient;
