import { DashboardLayout } from "components/layouts";
import { StorefrontForm } from "components/Storefront";

const StorefrontFormPage = () => {
  return (
    <DashboardLayout>
      <StorefrontForm />
    </DashboardLayout>
  );
};

export default StorefrontFormPage;
