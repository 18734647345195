import { Form } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";

export const SearchBar = () => {
  return (
    <div className="w-90% search-bar mx-2 mt-2">
      <FaSearch
        style={{ marginLeft: "1rem", position: "absolute" }}
        color="white"
      />
      <Form.Control className="search-input-venues" placeholder="Search" />
    </div>
  );
};
