/* eslint-disable react/no-unknown-property */
/* eslint-disable quotes */
import circle from "maps/circle.png";
import { useState, useRef, useMemo } from "react";
import { useTexture, Text, Html } from "@react-three/drei";
import { CanvasTooltip } from "./CanvasTooltip";
import { MeshBasicMaterial, PlaneGeometry } from "three";

const geometry = new PlaneGeometry(1, 1, 1);

const Hotspot = ({
  position,
  onHover,
  onPlacementModal,
  onModalMode,
  setLocation,
  addProductToPosition,
  setDraggedProduct,
  draggedProduct,
}) => {
  const [hoveredPosition, setHoveredPosition] = useState(false);
  const texture = useTexture(circle);
  const [hovered, setHovered] = useState(false);
  const ref = useRef();

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const handleDrop = () => {
    addProductToPosition(position.location);
    setDraggedProduct(false);
    onPlacementModal(false);
  };

  const handlePlaceProduct = () => {
    if (draggedProduct) {
      addProductToPosition(position.location);
      setDraggedProduct(false);
    }
  };

  const material = useMemo(() => {
    return new MeshBasicMaterial({
      side: 2,
      map: texture,
      transparent: true,
      color: draggedProduct ? "lightyellow" : "white",
    });
  }, [texture, draggedProduct]);

  const handlePointerOver = () => {
    setHovered(true);
    onHover(ref);
  };

  const handlePointerOut = () => {
    setHoveredPosition("");
    setHovered(false);
    onHover(null);
  };

  const handleClick = () => {
    onPlacementModal(true);
    onModalMode("dropbox");
    setLocation(position.location);
  };

  return (
    <>
      <mesh
        ref={ref}
        position={[
          position.position[0],
          position.position[1],
          position.position[2],
        ]}
        scale={1}
        rotation={position.rotation}
        geometry={geometry}
        material={material}
        material-envMapIntensity={hovered ? 100 : 2}
        onClick={handleClick}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
      >
        <CanvasTooltip
          hover={hovered}
          message="Click to add product here"
          position={position.position}
          rotation={position.rotation}
        />
        <Html>
          <div
            className={
              "hotspot-draggable d-flex align-items-center justify-content-center"
            }
            onDrop={handleDrop}
            onPointerOver={handlePlaceProduct}
            onDragOver={(event) => allowDrop(event)}
          >
            {""}
          </div>
        </Html>
      </mesh>
      <Text
        position={position.position}
        rotation={position.rotation}
        scale={0.5}
        whiteSpace="normal"
        color="black"
      >
        {position.location}
      </Text>
    </>
  );
};

export default Hotspot;
