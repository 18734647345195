import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { ShopifyForm } from "components/productOptions";
import { AccountContext } from "components/providers/Account";
import StorefrontSelector from "components/CreateProduct/StorefrontSelector";
import { shopifyAllProducts } from "helpers";
import ShopifyProductsTable from "components/productOptions/ShopifyProductsTable";
import { useShowroomServiceFetch } from "hooks";
import { FaArrowLeft } from "react-icons/fa";

const CreateProductsPage = () => {
  const [showroom, setShowroom] = useState("");
  const [template, setTemplate] = useState("");
  const { getSession } = useContext(AccountContext);
  const [token, setToken] = useState(null);
  const [selectedStorefront, setSelectedStorefront] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [products, setProducts] = useState([]);
  const [organizationId, setOrganizationId] = useState("");
  const [orgProductData, setOrgProductData] = useState(new Map());
  const navigate = useNavigate();
  const [mode, setMode] = useState();

  const { data, error, loading } = useShowroomServiceFetch(
    `organizations/${organizationId}/products`
  );

  useLayoutEffect(() => {
    getSession().then((data) => {
      setOrganizationId(data.idToken.payload["custom:org_id"]);
    });
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const products = new Map();
      data.forEach((product) => {
        products.set(product.serial_number, product);
      });
      setOrgProductData(products);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (
      selectedStorefront?.domain?.length > 0 &&
      selectedStorefront?.token?.length > 0
    ) {
      try {
        shopifyAllProducts({
          shopDom: selectedStorefront.domain,
          shopTok: selectedStorefront.token,
        }).then((data) => {
          setProducts(data);
        });
      } catch (error) {
        //error
      }
    }
  }, [selectedStorefront]);

  useLayoutEffect(() => {
    getSession().then((data) => {
      setToken(data.idToken.jwtToken);
      if (data.idToken.payload["custom:org_id"] === "mantisxr") {
        setIsAdmin(true);
      }
    });
  }, []);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    setMode(searchParams.get("mode"));
  }, [searchParams]);

  useEffect(() => {
    if (
      searchParams.get("organization") &&
      searchParams.get("showroom") &&
      searchParams.get("template")
    ) {
      setOrganizationId(searchParams.get("organization"));
      setTemplate(searchParams.get("template"));

      const data = searchParams
        .get("showroom")
        ?.toLowerCase()
        .replace(/ /g, "-");

      setShowroom(data);
    }
  }, []);

  const handleBackButton = () => {
    if (mode === "products") {
      navigate("/dashboard/products");
    } else {
      navigate("/dashboard/models");
    }
  };

  return (
    <>
      <div className="main-heading">
        <div className="lato-regular mt-4 clickable-text-large" onClick={handleBackButton}>
          <FaArrowLeft className="me-2" />
          <u className="text-light">
            Back to {mode === "products" ? "Products" : "Models"}
          </u>
        </div>
      </div>
      <div className="main-heading w-100 d-flex mt-2">
        <h2>Add {mode}</h2>
        {token && (
          <div className="me-1">
            <StorefrontSelector
              token={token}
              setSelectedStorefront={setSelectedStorefront}
              setOrganizationId={setOrganizationId}
              isAdmin={organizationId === "mantisxr"}
              organizationId={organizationId}
            />
          </div>
        )}
      </div>
      {selectedStorefront ? (
        <ShopifyProductsTable
          orgProductData={orgProductData}
          mode={mode}
          setOrgProductData={setOrgProductData}
          storefront={selectedStorefront}
          products={products}
          token={token}
          template={template}
          organization={organizationId}
          showroom={showroom}
        />
      ) : (
        <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
          <div id="ellipse-container">
            <div id="ellipse-1"></div>
            <div id="ellipse-2"></div>
            <div id="ellipse-3"></div>
            <div id="ellipse-4"></div>
          </div>
          <div id="instruction-text" className="lato-regular text-light">
            Select your storefront to get started
          </div>
        </div>
      )}
    </>
  );
};

export default CreateProductsPage;
