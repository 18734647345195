const logoFilter = ({ logos, nameInput }) => {
  let filteredData;

  if (nameInput.length > 0) {
    filteredData = logos.filter(
      (logo) =>
        logo.alias
          .toLocaleLowerCase()
          .indexOf(nameInput.toLocaleLowerCase()) !== -1
    );
  } else {
    filteredData = logos;
  }

  return filteredData;
};

export default logoFilter;
