/* eslint-disable react/no-unknown-property */
import { Canvas } from "@react-three/fiber";
import { Pagination } from "components/globals";
import ProductPreview from "components/globals/ProductPreview";
import { Suspense, useState } from "react";
import { FaEllipsisH } from "react-icons/fa";

const ProductCards = ({
  products,
  setSelectedProduct,
  selectedLocation,
  addProductToPosition,
  setDraggedProduct,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [optionsPerPage] = useState(6);

  const indexOfLastOption = currentPage * optionsPerPage;
  const indexOfFirstOption = indexOfLastOption - optionsPerPage;
  const currentOptions = products.slice(indexOfFirstOption, indexOfLastOption);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const addProductToLocation = (product) => {
    if (selectedLocation) {
      setSelectedProduct(product);
      addProductToPosition(selectedLocation);
    }
  };

  const paginationControl = (event) => {
    event.preventDefault();

    const button = event.currentTarget;

    if (
      button.name === "next" &&
      currentPage < Math.ceil(products.length / optionsPerPage)
    ) {
      setCurrentPage(currentPage + 1);
    }

    if (button.name === "previous" && currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="d-flex flex-row flex-wrap w-100 h-100 justify-content-center">
      {currentOptions.map((product) => {
        return (
          <div
            draggable
            onDragStart={() => {
              setDraggedProduct(true);
              setSelectedProduct(product);
            }}
            onDragEnd={() => setDraggedProduct(false)}
            className="venue-filter-card m-1 d-flex justify-content-end"
            onClick={() => addProductToLocation(product)}
            key={product.id}
          >
            <div className="ellpisis-icon-img logo">
              <FaEllipsisH
                color="white"
                onMouseOver={() => setSelectedProduct(product)}
                style={{ zIndex: 20 }}
              />
            </div>
            <Canvas>
              <ambientLight intensity={1} />
              <Suspense fallback={null}>
                <ProductPreview
                  textureUrl={null}
                  geometry={product.path}
                  geometryType="url"
                />
              </Suspense>
            </Canvas>
          </div>
        );
      })}
    </div>
    <div className="d-flex justify-content-center">
      {products.length > 1 && (
        <div className="main-pagination-container">
          <div className="pagination-container">
            <Pagination
              optionsPerPage={optionsPerPage}
              totalOptions={products.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default ProductCards;
