import { useState, useContext, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { Button, ShowAlert } from "components/globals";
import * as AiIcons from "react-icons/ai";
import { RegistrationLayout } from "components/layouts";
import { AccountContext } from "components/providers/Account";

const LoginPage = () => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [passwordView, setPasswordView] = useState(false);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const navigate = useNavigate();

  const { authenticate } = useContext(AccountContext);

  const onSubmit = (event) => {
    event.preventDefault();

    setButtonLoader(true);

    if (null !== emailRef.current && null !== passwordRef.current) {
      authenticate(emailRef.current.value, passwordRef.current.value)
        .then((data) => {
          if (data) {
            ShowAlert({
              title: "Welcome to MANTIS XR Admin Dashboard!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            }).then(() => {
              setButtonLoader(false);
              navigate("/dashboard");
            });
          }
        })
        .catch((err) => {
          if (err) {
            ShowAlert({
              title: "Oooopsss.............!!!",
              text: `${err.message}`,
              icon: "error",
              showConfirmButton: false,
              timer: 2000,
            });
            setButtonLoader(false);
          }
        });
    }
  };

  const handlePasswordView = (event) => {
    event.preventDefault();
    setPasswordView(!passwordView);
  };

  return (
    <RegistrationLayout title="Welcome To">
      <Form onSubmit={onSubmit}>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            ref={emailRef}
            required
            type="email"
            placeholder="Email"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <Row>
            <Col lg={11}>
              <Form.Control
                ref={passwordRef}
                required
                type={passwordView ? "text" : "password"}
                placeholder="Password"
              />
            </Col>
            <Col lg={1}>
              <button
                onClick={handlePasswordView}
                style={{ border: "none", background: "none" }}
              >
                {passwordView ? (
                  <AiIcons.AiOutlineEyeInvisible />
                ) : (
                  <AiIcons.AiOutlineEye />
                )}
              </button>
            </Col>
          </Row>
        </Form.Group>
        <div className="text-end">
          <NavLink className="link-dark" to="/password-reset">
            Forgot password?
          </NavLink>
        </div>
        <Button isLoading={buttonLoader} type="submit">
          Sign In
        </Button>
      </Form>
    </RegistrationLayout>
  );
};

export default LoginPage;
